import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenStorageService } from '../shared/token-storage.service';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams} from '@angular/common/http';
import { Request } from './models/request.model';
import { environment } from '../../environments/environment';
import { map } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private apiUrl = '/api/create-checkout-session';
  data: any;

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {}

  CreateCheckoutSession(): Observable<{ clientSecret: string }> {
    return this.http.post<{ clientSecret: string }>(this.apiUrl, {});
  }

  GetEventParticipantPaymentInformation(event_id: number, event_participant_id: number) {

    let token =  this.tokenStorageService.getToken();
    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + token});

      const httpOptions = {
        headers: headers_object
      };

      let queryParams = new HttpParams().set('event_id', event_id).set('event_participant_id', event_participant_id);

    return this.http.get<Request>(environment.url+'/user/participants/event_participant_payment_info', {params: queryParams, ...httpOptions}).pipe()
  }

  AttemptScholarshipPayment(event_id: number, event_participant_id: number) {
    let data = {
      event_id: event_id,
      event_participant_id: event_participant_id
    }

    let token =  this.tokenStorageService.getToken();
    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + token});

      const httpOptions = {
        headers: headers_object
      };
    return this.http.post<Request>(environment.url+'/admin/users/scholarship-payment', data, httpOptions).pipe()
  }

  RequestStripePaymentView(event_id: number, event_participant_id: number, user_id: number, scholarship_request: string) {
    let t =  this.tokenStorageService.getToken();

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+t});

      let data = {
        user_id: user_id,
        event_id: event_id,
        event_participant_id: event_participant_id,
        scholarship_request: scholarship_request
      }

      const httpOptions = {
        headers: headers_object
      };
    return this.http.post<Request>(environment.url+'/admin/users/stripe/create-checkout-web', data, httpOptions).pipe()
  }

}
