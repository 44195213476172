
      <div class="title"> User Management </div>

      <div class="entry_title">
        Role <br>
        <select class="select" style="width: 25%" [(ngModel)]="userRole" name="dropdown">
          <option value="value1">user</option>
          <option value="value2">admin</option>
        </select>
      </div>

      <div clas="container">
        <div class="selector-box entry_title">
          First Name <br>
          <input class="select" type="text" placeholder="First Name" [(ngModel)]="userFirstName">
        </div>
        <div class="selector-box entry_title">
          Last Name <br>
          <input class="select" type="text" placeholder="Last Name" [(ngModel)]="userLastName">
        </div>
      </div>
      <div class="entry_title">
        <div class="selector-box entry_title">
          Email <br>
          <input class="select" type="text" placeholder="Email" [(ngModel)]="userEmail">
        </div>
        <div class="selector-box entry_title">
          Phone <br>
          <input class="select" type="text" placeholder="Phone" [(ngModel)]="userPhone" (keydown)="PreventNonNumeric($event)">
        </div>
      </div>
      <div class="entry_title">
          Address
        <input class="select" type="text" placeholder="Address" [(ngModel)]="userAddress"><br>
      </div>
      <div class="entry_title">
        Notes
        <textarea class="select" type="text" placeholder="Notes" rows="7" [(ngModel)]="userNote"></textarea><br>
      </div>

      <button class="button" (click)="Save()"> Save </button> <br>


