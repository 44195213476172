<div>


  <div class="horizontal-container">
    <div class="horizontal-item" style="width:50%">
      <div class="topbar_events">
        <button class="create_button" (click)="NavigateCreateEventsView()" >Create Event</button>
      </div>
    </div>
    <div class="horizontal-item" style="width:50%">
      <div class="topbar_events">
        <mat-form-field>
          <mat-label>Event Types</mat-label>
          <mat-select [formControl]="eventTypes" multiple>
            @for (eventType of eventTypesList; track eventType) {
              <mat-option [value]="eventType">{{eventType}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

    <mat-table class="table_tiles" [dataSource]="filteredDataSource">
      <!-- Event Name Definition -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Event </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.title}} </mat-cell>
      </ng-container>

      <!-- Date Definition -->
      <!-- <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.date}} </mat-cell>
      </ng-container> -->

      <!-- Status Definition -->
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.status}} </mat-cell>
      </ng-container>


      <!-- Status Definition -->
      <ng-container matColumnDef="participants">
        <mat-header-cell *matHeaderCellDef> Participants  </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button class="icon_button" (click)="DisplayParticipantsView(row.id)"> <img class="svg" src="assets/svg/people.svg" alt="participants"> </button>
          <div>
            {{row.current_participants}}/{{row.max_participants}}
          </div>
        </mat-cell>
      </ng-container>

         <!-- Status Definition -->
         <ng-container matColumnDef="attendance">
          <mat-header-cell *matHeaderCellDef> Attendance  </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button class="icon_button" (click)="DisplayAttendanceView(row.id)"> <img class="svg" src="assets/svg/calendar.svg" alt="attendance"> </button>
          </mat-cell>
        </ng-container>

              <!-- Status Definition -->
      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef> Edit  </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button class="icon_button" (click)="DisplayEventManageView(row.id)"> <img class="svg" src="assets/svg/edit.svg" alt="manage"> </button>
        </mat-cell>
      </ng-container>


      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="['name',  'status', 'participants', 'attendance','edit']"></mat-header-row>
      <mat-row *matRowDef="let row; columns: ['name',  'status', 'participants', 'attendance' , 'edit']"></mat-row>
    </mat-table>
  </div>
