import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Request } from './models/request.model';
import { TokenStorageService } from '../shared/token-storage.service';
import { environment } from '../../environments/environment';
import { Observable, map } from 'rxjs';
import { EventForList } from '../events-main/events-main.component';
import { DateItem, Question } from '../events-manage/events-manage.component';
import { Answer } from '../event-participant-questions/event-participant-questions.component';



  @Injectable({
    providedIn: 'root'
  })

  export class EventService {

   data: any;
   public newText: string;

   constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
      this.newText = "Nothing Selected";
   }



  UploadThumbnail(eventID: number, image: File) {
    const formData = new FormData();
    formData.append('eventID', eventID.toString());
    formData.append('image', image);

    let token =  this.tokenStorageService.getToken();
    let headers_object = new HttpHeaders({
      'Authorization': "Bearer " + token});

    const httpOptions = {
      headers: headers_object
    };

    return this.http.post<Request>(environment.url+'/admin/events/upload_image', formData, httpOptions).pipe()
  }


  ArchiveEvent(eventID: number){
    let data = {
      eventID: eventID
    }
    let token =  this.tokenStorageService.getToken();
    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + token});

      const httpOptions = {
        headers: headers_object
      };
    return this.http.post<Request>(environment.url+'/admin/events/archive', data, httpOptions).pipe()
  }

  DeleteImage(eventID: number){
    let data = {
      eventID: eventID
    }
    let token =  this.tokenStorageService.getToken();
    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + token});

      const httpOptions = {
        headers: headers_object
      };
    return this.http.post<Request>(environment.url+'/admin/events/delete_image', data, httpOptions).pipe()
  }


  CreateEventParticipant(eventID: number, participantID: number) {
    let data = {
      eventID: eventID,
      participantID: participantID
    }

    let token =  this.tokenStorageService.getToken();
    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + token});

      const httpOptions = {
        headers: headers_object
      };
    return this.http.post<Request>(environment.url+'/admin/events/participants/add', data, httpOptions).pipe()
  }

  EditEventParticipant(eventParticipantID: number, eventParticipantDetails: any) {
    let data = {
      eventParticipantID: eventParticipantID,
      ... eventParticipantDetails
    }

    let token =  this.tokenStorageService.getToken();
    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + token});

      const httpOptions = {
        headers: headers_object
      };
    return this.http.post<Request>(environment.url+'/admin/events/participants/edit', data, httpOptions).pipe()
  }

  RemoveEventParticipant(eventParticipantID: number) {
    let data = {
      id: eventParticipantID,
    }

    let token =  this.tokenStorageService.getToken();
    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + token});

      const httpOptions = {
        headers: headers_object
      };
    return this.http.post<Request>(environment.url+'/admin/events/participants/remove', data, httpOptions).pipe()
  }


  GetEventParticipants(eventID: number) {

    let t =  this.tokenStorageService.getToken();

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+t});

      const httpOptions = {
        headers: headers_object
      };

      const queryParams = new HttpParams().set('eventID', eventID);

    return this.http.get<Request>(environment.url+'/admin/events/participants/list', {params: queryParams, ...httpOptions}).pipe()
  }

  GetEventParticipant(event_participant_ID: number) {
    let t =  this.tokenStorageService.getToken();

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+t});

      const httpOptions = {
        headers: headers_object
      };
      console.log("event_participant_ID sent: "+event_participant_ID)
      const queryParams = new HttpParams().set('event_participant_ID', event_participant_ID);

    return this.http.get<Request>(environment.url+'/admin/events/participants/get_participant', {params: queryParams, ...httpOptions}).pipe(
      map(response => {
        let handledResponse: any = response;

        let baseResponse: any = {
          user_id: handledResponse.participant.user_id,
          participant_id: handledResponse.participant.id,
          event_participant_id: handledResponse.id,
          event_id: handledResponse.event_id,
          first_name: handledResponse.participant.first_name,
          last_name: handledResponse.participant.last_name,
          birthdate: handledResponse.participant.birthdate
        };

        let { id, ...participantDetails } = handledResponse.participant.participant_details[0];

        // Merge participant_details onto the response object
        return {
          ...baseResponse,
          ...participantDetails
        };
     }))
  }


  GetEventParticipantQuestions(event_ID: number, event_participant_ID: number) {
    let t =  this.tokenStorageService.getToken();

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+t});

      const httpOptions = {
        headers: headers_object
      };

      let queryParams = new HttpParams().set('event_participant_ID', event_participant_ID);
      queryParams = queryParams.set('event_ID', event_ID);

    return this.http.get<Request>(environment.url+'/admin/events/participants/get_event_participant_questions', {params: queryParams, ...httpOptions});
  }

  PublishEvent(eventID: number){
    let data = {
      eventID: eventID
    }
    let token =  this.tokenStorageService.getToken();
    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + token});

      const httpOptions = {
        headers: headers_object
      };
    return this.http.post<Request>(environment.url+'/admin/events/publish', data, httpOptions).pipe()
  }

  CreateEvent(name: string, date: Date, status: string, facilitator: string, description: string, location: number, address: string, min_age: number, max_age: number, is_all_ages: boolean, file_name: string, schedules: DateItem[], questions: Question[], eventPrice: number, maxParticipants: number, maxScholarships: number,virtualEvent : boolean ) {
    let data = {
      title: name,
      date: date,
      status: status,
      description: description,
      location_id: location,
      address: address,
      min_age: min_age,
      max_age: max_age,
      is_all_ages: is_all_ages,
      facilitator: facilitator,
      file_name: file_name,
      schedules: schedules,
      questions: questions,
      event_price: eventPrice,
      max_participants: maxParticipants,
      max_scholarships: maxScholarships,
      is_virtual : virtualEvent

    }

    let token =  this.tokenStorageService.getToken();
    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + token});

      const httpOptions = {
        headers: headers_object
      };
    return this.http.post<Request>(environment.url+'/admin/events/create',data, httpOptions).pipe()
  }

  EditEvent(id: number,
    name: string,
    date: Date,
    status: string,
    facilitator: string,
    description: string,
    file_name: string,
    schedules: DateItem[],
    questions: Question[],
    eventPrice: number,
    maxParticipants: number,
    maxScholarships: number,
    location: number,
    address: string,
    min_age: number,
    max_age: number,
    all_ages: boolean,
    virtual_event: boolean,
    virtual_event_optional: boolean ) {

    let data = {
      eventID: id,
      title: name,
      date: date,
      status: status,
      description: description,
      facilitator: facilitator,
      file_name: file_name,
      schedules: schedules,
      questions: questions,
      event_price: eventPrice,
      max_participants: maxParticipants,
      max_scholarships: maxScholarships,
      is_all_ages: all_ages,
      min_age: min_age,
      max_age: max_age,
      address: address,
      is_virtual: virtual_event,
      is_optional_virtual: virtual_event_optional,
      location_id: location
    }

    let token =  this.tokenStorageService.getToken();
    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + token});

      const httpOptions = {
        headers: headers_object
      };
    return this.http.post<Request>(environment.url+'/admin/events/edit',data, httpOptions).pipe()
  }

  GetEvents(): Observable<EventForList[]>{
    let t =  this.tokenStorageService.getToken();

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+t});

      const httpOptions = {
        headers: headers_object
      };
    return this.http.get<Request>(environment.url+'/admin/events/list', httpOptions).pipe(
      map((response: Request) => this.formatEvents(response))
    )
  }


  GetEvent(id: number): Observable<any>{
    let t =  this.tokenStorageService.getToken();

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+t});

      const httpOptions = {
        headers: headers_object
      };

      const queryParams = new HttpParams().set('eventID', id);

    return this.http.get<Request>(environment.url+'/admin/events/get_event', {params: queryParams, ...httpOptions}).pipe()
  }

  GetLocations(): Observable<any>{
    let t =  this.tokenStorageService.getToken();

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+t});

      const httpOptions = {
        headers: headers_object
      };

    return this.http.get<Request>(environment.url+'/admin/events/locations', httpOptions).pipe()
  }

  SubmitEventParticipantAnswers(event_id: number, event_participant_id: number, answers: Answer[], project_permission: boolean, photo_permission: boolean) {
    let data = {
      event_id: event_id,
      event_participant_id: event_participant_id,
      answers: answers,
      project_permission: project_permission,
      photo_permission: photo_permission
    }

    let token =  this.tokenStorageService.getToken();
    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + token});

      const httpOptions = {
        headers: headers_object
      };
    return this.http.post<Request>(environment.url+'/admin/events/participants/submit_question_answers', data, httpOptions).pipe()
  }

  GetEventParticipantPaymentInformation(event_id: number, event_participant_id: number) {

    let token =  this.tokenStorageService.getToken();
    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + token});

      const httpOptions = {
        headers: headers_object
      };

      let queryParams = new HttpParams().set('event_id', event_id).set('event_participant_id', event_participant_id);

    return this.http.get<Request>(environment.url+'/user/participants/event_participant_payment_info', {params: queryParams, ...httpOptions}).pipe()
  }

  UpdateAttendanceSheet(event_id: number, attendance_data: any){
    let data = {
      event_id: event_id,
      attendance_data: attendance_data
    }

    let token =  this.tokenStorageService.getToken();
    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + token});

      const httpOptions = {
        headers: headers_object
      };
    return this.http.post<Request>(environment.url+'/admin/events/update_attendance_sheet', data, httpOptions).pipe()
  }

  GetAttendanceSheet(event_id: number){
    let token =  this.tokenStorageService.getToken();
    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + token
    });

    const httpOptions = {
      headers: headers_object
    };

    let queryParams = new HttpParams().set('event_id', event_id);
    return this.http.get<Request>(environment.url+'/admin/events/attendance_sheet', {params: queryParams, ...httpOptions}).pipe()
  }

  private formatEvents(response: Request): EventForList[] {
    // Assuming your response structure is compatible, format it into EventForList array
    const events: EventForList[] = [];

    // Assuming response contains array of events
    (response as any).forEach((event: any) => {
      const formattedEvent: EventForList = {
        id: event.id,
        is_archived: event.is_archived,
        event_schedules: event.event_schedules,
        end_date: event.end_date,
        location: event.location,
        title: event.title,
        date: new Date(event.date),
        status: event.status,
        current_participants: event.current_participants,
        max_participants: event.max_participants,
      };
      events.push(formattedEvent);
    });

    return events;
  }
 }


