import { ActivatedRoute, Router} from '@angular/router';
import { Component, Renderer2, ElementRef, ViewChild, SimpleChanges, ViewChildren, QueryList} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule} from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { ParticipantService } from '../shared/participant-service.service';

@Component({
  selector: 'app-user-participant-edit',
  standalone: true,
  imports: [CommonModule, FormsModule, MatFormFieldModule],
  providers: [ParticipantService],
  templateUrl: './user-participant-edit.component.html',
  styleUrl: '../shared/forms-styles.css'
})

export class UserParticipantEditComponent {
  @ViewChild('edit_button') eB!: ElementRef;
  @ViewChildren("input_container") input_container!: QueryList<ElementRef>;


  UserParticipantData: any = null;

  userID: any = null;
  participantID: any = null;
  canEdit: boolean = true;


  showEmergencyContact: any;
  emergencyContactFirstNameTempString: any = '';
  emergencyContactLastNameTempString: any = '';
  emergencyContactPhoneTempString: any = '';

  showAuthorizedPickup: any;
  authorizedPickupFirstNameTempString: any = '';
  authorizedPickupLastNameTempString: any = '';
  authorizedPickupPhoneTempString: any = '';


  constructor (private participantService: ParticipantService, private route: ActivatedRoute, private router: Router, private renderer: Renderer2) {

    this.emergencyContactFirstNameTempString = {
      1: '',
      2: '',
      3: ''
    }

    this.emergencyContactLastNameTempString = {
      1: '',
      2: '',
      3: ''
    }

    this.emergencyContactPhoneTempString = {
      1: '',
      2: '',
      3: ''
    }

    this.showEmergencyContact = {
      1: true,
      2: false,
      3: false
    }

    this.authorizedPickupFirstNameTempString = {
      1: '',
      2: '',
      3: ''
    }

    this.authorizedPickupLastNameTempString = {
      1: '',
      2: '',
      3: ''
    }

    this.authorizedPickupPhoneTempString = {
      1: '',
      2: '',
      3: ''
    }

    this.showAuthorizedPickup = {
      1: true,
      2: false,
      3: false
    }

  }

  ngOnInit(): void{
    this.userID = this.route.snapshot.paramMap.get('id');
    this.participantID = this.route.snapshot.paramMap.get('participantID');
    const canEditValue = this.route.snapshot.paramMap.get('canEdit');
    this.canEdit = canEditValue?.toLocaleLowerCase() == 'true';
  }

  ngAfterViewInit() {
    this.FetchParticipant();
    // this.externalEvents.changes.subscribe((elements) => {
    //   // Do something with elements or with this.externalEvents
    //   let element = this.externalEvents.first;
    //   console.log("Changes detected", element);
    // }

    this.input_container.changes.subscribe((elements) => {
        // Do something with elements or with this.externalEvents
        let element = this.input_container.first;
        console.log("Changes detected", element);
        if (!this.canEdit){
          const inputs = element.nativeElement.querySelectorAll('input');
          inputs.forEach((input: any) => {
            this.renderer.setProperty(input, 'disabled', true);
          });
          const textarea = element.nativeElement.querySelectorAll('textarea');
          textarea.forEach((input: any) => {
            this.renderer.setProperty(input, 'disabled', true);
          });
        }
      });
  }

  DisableMedicalInfo() {
    return !this.UserParticipantData.has_medical_info;
  }

  DisableLearnerPreferences() {
    return !this.UserParticipantData.has_learner_preferences;
  }


  PreventNonNumeric(event: KeyboardEvent): void {
    const key = event.key;

    // Allow navigation keys like backspace, delete, arrows, etc.
    if (key === 'Backspace' || key === 'Delete' || key === 'ArrowLeft' || key === 'ArrowRight') {
      return;
    }

    // Prevent non-numeric input (excluding keys like tab, enter, etc.)
    if (!/^\d$/.test(key)) {
      event.preventDefault(); // Stop the key from being added to the input field
    } else {
      // Ensure the input is a number
      const input = event.target as HTMLInputElement;
      if (input.value.length >= 10) {
        event.preventDefault();
      }
    }
  }


  FetchParticipant(): void {
    this.participantService.GetUserParticipant(this.participantID).subscribe( response =>{
      this.UserParticipantData = response;

      this.emergencyContactFirstNameTempString[1] = this.UserParticipantData.emergency_contact_firstname_1;
      this.emergencyContactFirstNameTempString[2] = this.UserParticipantData.emergency_contact_firstname_2;
      this.emergencyContactFirstNameTempString[3] = this.UserParticipantData.emergency_contact_firstname_3;

      this.emergencyContactLastNameTempString[1] = this.UserParticipantData.emergency_contact_lastname_1;
      this.emergencyContactLastNameTempString[2] = this.UserParticipantData.emergency_contact_lastname_2;
      this.emergencyContactLastNameTempString[3] = this.UserParticipantData.emergency_contact_lastname_3;

      this.emergencyContactPhoneTempString[1] = this.UserParticipantData.emergency_contact_phone_1;
      this.emergencyContactPhoneTempString[2] = this.UserParticipantData.emergency_contact_phone_2;
      this.emergencyContactPhoneTempString[3] = this.UserParticipantData.emergency_contact_phone_3;

      this.authorizedPickupFirstNameTempString[1] = this.UserParticipantData.authorized_pickup_firstname_1;
      this.authorizedPickupFirstNameTempString[2] = this.UserParticipantData.authorized_pickup_firstname_2;
      this.authorizedPickupFirstNameTempString[3] = this.UserParticipantData.authorized_pickup_firstname_3;

      this.authorizedPickupLastNameTempString[1] = this.UserParticipantData.authorized_pickup_lastname_1;
      this.authorizedPickupLastNameTempString[2] = this.UserParticipantData.authorized_pickup_lastname_2;
      this.authorizedPickupLastNameTempString[3] = this.UserParticipantData.authorized_pickup_lastname_3;

      this.authorizedPickupPhoneTempString[1] = this.UserParticipantData.authorized_pickup_phone_1;
      this.authorizedPickupPhoneTempString[2] = this.UserParticipantData.authorized_pickup_phone_2;
      this.authorizedPickupPhoneTempString[3] = this.UserParticipantData.authorized_pickup_phone_3;

      // Check if the emergency contact and authorized pickup fields are present and set the show flags accordingly
      if (this.IsEmergencyContactLinePresent(1)){
        this.showEmergencyContact[2] = true;
      }
      if (this.IsEmergencyContactLinePresent(2)){
        this.showEmergencyContact[3] = true;
      }

      if (this.IsAuthorizedPickupLinePresent(1)){
        this.showAuthorizedPickup[2] = true;
      }
      if (this.IsAuthorizedPickupLinePresent(2)){
        this.showAuthorizedPickup[3] = true;
      }

    });
  }

  navigateBack(): void {
    this.router.navigate(['/users-participants', this.userID]);
  }

  EditUserParticipant(): void {

    if (this.canEdit){

      const validPhone = " A valid phone number is 10 digits, without the country code.";
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const phoneRegex = /^\d{10}$/;

      if (!emailRegex.test(this.UserParticipantData.email)) {
        alert("Please enter a valid email address.");
        return;
      }

      if (!phoneRegex.test(this.UserParticipantData.phone)) {
        alert("Please enter a valid participant phone number."  + validPhone);
        return;
      }

      for(let i = 1; i < 4; i++){

        let checkEmergencyValidity = false;
        if (this.emergencyContactFirstNameTempString[i] != null && this.emergencyContactFirstNameTempString[i] != ""){
          checkEmergencyValidity = true;
        }
        if  (this.emergencyContactLastNameTempString[i] != null && this.emergencyContactLastNameTempString[i] != "") {
          checkEmergencyValidity = true;
        }
        if  (this.emergencyContactPhoneTempString[i] != null && this.emergencyContactPhoneTempString[i] != "") {
          checkEmergencyValidity = true;
        }

        if (checkEmergencyValidity){
          if (this.emergencyContactFirstNameTempString[i] == null || this.emergencyContactFirstNameTempString[i] == ""){
            alert("Please enter a valid emergency contact "+ i + " first name.");
            return;
          }
          if (this.emergencyContactLastNameTempString[i] == null || this.emergencyContactLastNameTempString[i] == ""){
            alert("Please enter a valid emergency contact "+ i + " last name.");
            return;
          }
          if (this.emergencyContactPhoneTempString[i] == null || this.emergencyContactPhoneTempString[i] == "" || !phoneRegex.test(this.emergencyContactPhoneTempString[i])){
            alert("Please enter a valid emergency contact "+ i + " phone number." + validPhone);
            return;
          }
        }

        let checkAurhorizedValidity = false;

        if (this.authorizedPickupFirstNameTempString[i] != null && this.authorizedPickupFirstNameTempString[i] != ""){
          checkAurhorizedValidity = true;
        }
        if  (this.authorizedPickupLastNameTempString[i] != null && this.authorizedPickupLastNameTempString[i] != "") {
          checkAurhorizedValidity = true;
        }
        if  (this.authorizedPickupPhoneTempString[i] != null && this.authorizedPickupPhoneTempString[i] != "") {
          checkAurhorizedValidity = true;
        }

        if (checkAurhorizedValidity){
          if (this.authorizedPickupFirstNameTempString[i] == null || this.authorizedPickupFirstNameTempString[i] == ""){
            alert("Please enter a valid authorized pickup "+ i + " first name.");
            return;
          }
          if (this.authorizedPickupLastNameTempString[i] == null || this.authorizedPickupLastNameTempString[i] == ""){
            alert("Please enter a valid authorized pickup "+ i + " last name.");
            return;
          }
          if (this.authorizedPickupPhoneTempString[i] == null || this.authorizedPickupPhoneTempString[i] == "" || !phoneRegex.test(this.authorizedPickupPhoneTempString[i])){
            alert("Please enter a valid authorized pickup "+ i + " phone number." + validPhone);
            return;
          }
        }
      }

      if (!this.UserParticipantData.has_medical_info){

      }

      this.UserParticipantData = {
        id: this.participantID,
        user_id: this.userID,
        first_name: this.UserParticipantData.first_name,
        last_name: this.UserParticipantData.last_name,
        birthdate: this.UserParticipantData.birthdate,
        preferred_pronouns: this.UserParticipantData.preferred_pronouns,
        phone: this.UserParticipantData.phone,
        email: this.UserParticipantData.email,
        has_medical_info: this.UserParticipantData.has_medical_info,
        medical_info: this.UserParticipantData.has_medical_info ? this.UserParticipantData.medical_info : '',
        has_learner_preferences: this.UserParticipantData.has_learner_preferences,
        learner_preferences: this.UserParticipantData.has_learner_preferences ? this.UserParticipantData.learner_preferences : '',
        note: this.UserParticipantData.note,
        emergency_contact_firstname_1: this.emergencyContactFirstNameTempString[1],
        emergency_contact_firstname_2: this.emergencyContactFirstNameTempString[2],
        emergency_contact_firstname_3: this.emergencyContactFirstNameTempString[3],
        emergency_contact_lastname_1: this.emergencyContactLastNameTempString[1],
        emergency_contact_lastname_2: this.emergencyContactLastNameTempString[2],
        emergency_contact_lastname_3: this.emergencyContactLastNameTempString[3],
        emergency_contact_phone_1: this.emergencyContactPhoneTempString[1],
        emergency_contact_phone_2: this.emergencyContactPhoneTempString[2],
        emergency_contact_phone_3: this.emergencyContactPhoneTempString[3],
        authorized_pickup_firstname_1: this.authorizedPickupFirstNameTempString[1],
        authorized_pickup_firstname_2: this.authorizedPickupFirstNameTempString[2],
        authorized_pickup_firstname_3: this.authorizedPickupFirstNameTempString[3],
        authorized_pickup_lastname_1: this.authorizedPickupLastNameTempString[1],
        authorized_pickup_lastname_2: this.authorizedPickupLastNameTempString[2],
        authorized_pickup_lastname_3: this.authorizedPickupLastNameTempString[3],
        authorized_pickup_phone_1: this.authorizedPickupPhoneTempString[1],
        authorized_pickup_phone_2: this.authorizedPickupPhoneTempString[2],
        authorized_pickup_phone_3: this.authorizedPickupPhoneTempString[3]
      }

      this.participantService.EditUserParticipant(this.UserParticipantData).subscribe( response =>{
        console.log(response);
        this.navigateBack();
      },
      error => {

        let errorString = '';

        // Access the nested errors object
        const errors = error.error.errors;

        // Iterate through the errors object
        Object.entries(errors).forEach(([field, errorMessages]) => {
          (errorMessages as string[]).forEach(errorMessage => {
            errorString += `${field}: ${errorMessage}\n`;
          });
        });
        alert("The following fields have issues: " + errorString);

        console.log('Error:', error);
      });
    }
  }

  OnEmergencyFirstNameInputBlur(inputValue: string, num: number) {
    this.emergencyContactFirstNameTempString[num]= inputValue;
    this.CleanUpEmergencyInputFields();
  }

  OnEmergencyLastNameInputBlur(inputValue: string, num: number) {
    this.emergencyContactLastNameTempString[num]= inputValue;
    this.CleanUpEmergencyInputFields();
  }

  OnEmergencyPhoneInputBlur(inputValue: string, num: number) {
    this.emergencyContactPhoneTempString[num]= inputValue;
    this.CleanUpEmergencyInputFields();
  }

  removeAuthorizedLine(num: number) {
    this.authorizedPickupFirstNameTempString[num] = '';
    this.authorizedPickupLastNameTempString[num] = '';
    this.authorizedPickupPhoneTempString[num] = '';
    this.showAuthorizedPickup[num] = false;
    this.CleanUpEmergencyInputFields();
  }

  showAuthorizedRemoveButton(num: number){
    if (this.authorizedPickupFirstNameTempString[num]?.length > 0 ||
        this.authorizedPickupLastNameTempString[num]?.length > 0 ||
        this.authorizedPickupPhoneTempString[num]?.length > 0){
      return true;
    }
    return false;
  }

  removeEmergencyContactLine(num: number) {
    this.emergencyContactFirstNameTempString[num] = '';
    this.emergencyContactLastNameTempString[num] = '';
    this.emergencyContactPhoneTempString[num] = '';
    this.showEmergencyContact[num] = false;
    this.CleanUpEmergencyInputFields();
  }

  showEmergencyContactRemoveButton(num: number){
    if (this.emergencyContactFirstNameTempString[num]?.length > 0 ||
        this.emergencyContactLastNameTempString[num]?.length > 0 ||
        this.emergencyContactPhoneTempString[num]?.length > 0){
      return true;
    }
    return false;
  }

  // Check if the emergency contact fields are present and return true or false
  IsEmergencyContactLinePresent(i: number): boolean{
    let isPresent = false;

    if (this.emergencyContactFirstNameTempString[i] != null && this.emergencyContactFirstNameTempString[i] != ''
      || this.emergencyContactLastNameTempString[i] != null && this.emergencyContactLastNameTempString[i] != ''
      || this.emergencyContactPhoneTempString[i] != null && this.emergencyContactPhoneTempString[i] != '')
      {
        isPresent = true;
      }
    return isPresent;
  }

  ValidateBirthdate(): void {

    // if UserParticipantData.birthdate is > today, set it to today
    let today = new Date();
    let birthdate = new Date(this.UserParticipantData.birthdate);
    if (birthdate > today){
      this.UserParticipantData.birthdate = today.toISOString().split('T')[0];
    }

  }


  // Clean up the emergency contact fields and restack entries
  CleanUpEmergencyInputFields(): void {

    let tempShuffleIndex = 1;
    this.showEmergencyContact[1] = true;
    this.showEmergencyContact[2] = false;
    this.showEmergencyContact[3] = false;

    for(let i = 1; i < 4; i++){
      if (this.IsEmergencyContactLinePresent(i))
      {
        this.emergencyContactFirstNameTempString[tempShuffleIndex] = this.emergencyContactFirstNameTempString[i]
        this.emergencyContactLastNameTempString[tempShuffleIndex] = this.emergencyContactLastNameTempString[i]
        this.emergencyContactPhoneTempString[tempShuffleIndex] = this.emergencyContactPhoneTempString[i]
        if (i > tempShuffleIndex){
          this.emergencyContactFirstNameTempString[i] = '';
          this.emergencyContactLastNameTempString[i] = '';
          this.emergencyContactPhoneTempString[i] = '';
        }

        this.showEmergencyContact[tempShuffleIndex+1] = true;
        tempShuffleIndex++;
      }
    }
  };

  OnAuthorizedFirstNameInputBlur(inputValue: string, num: number) {
    this.authorizedPickupFirstNameTempString[num]= inputValue;
    this.CleanUpAuthorizedInputFields();
  }

  OnAuthorizedLastNameInputBlur(inputValue: string, num: number) {
    this.authorizedPickupLastNameTempString[num]= inputValue;
    this.CleanUpAuthorizedInputFields();
  }

  OnAuthorizedPhoneInputBlur(inputValue: string, num: number) {
    this.authorizedPickupPhoneTempString[num]= inputValue;
    this.CleanUpAuthorizedInputFields();
  }

  // Check if the authorized pickup fields are present and return true or false
  IsAuthorizedPickupLinePresent(i: number): boolean{
    let isPresent = false;

    if (this.authorizedPickupFirstNameTempString[i] != null && this.authorizedPickupFirstNameTempString[i] != ''
      || this.authorizedPickupLastNameTempString[i] != null && this.authorizedPickupLastNameTempString[i] != ''
      || this.authorizedPickupPhoneTempString[i] != null && this.authorizedPickupPhoneTempString[i] != '')
      {
        isPresent = true;
      }
    return isPresent;
  }


  // Clean up the authorized pickup fields and restack entries
  CleanUpAuthorizedInputFields(): void {

    let tempShuffleIndex = 1;
    this.showAuthorizedPickup[1] = true;
    this.showAuthorizedPickup[2] = false;
    this.showAuthorizedPickup[3] = false;

    for(let i = 1; i < 4; i++){
      if (this.IsAuthorizedPickupLinePresent(i))
      {
        this.authorizedPickupFirstNameTempString[tempShuffleIndex] = this.authorizedPickupFirstNameTempString[i]
        this.authorizedPickupLastNameTempString[tempShuffleIndex] = this.authorizedPickupLastNameTempString[i]
        this.authorizedPickupPhoneTempString[tempShuffleIndex] = this.authorizedPickupPhoneTempString[i]
        if (i > tempShuffleIndex){
          this.authorizedPickupFirstNameTempString[i] = '';
          this.authorizedPickupLastNameTempString[i] = '';
          this.authorizedPickupPhoneTempString[i] = '';
        }

        this.showAuthorizedPickup[tempShuffleIndex+1] = true;
        tempShuffleIndex++;
      }
    }
  };

}
