import { Component } from '@angular/core';
import { TokenStorageService } from '../shared/token-storage.service';
import { AuthService } from '../shared/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { Location } from '@angular/common';


@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [FormsModule],
  providers: [TokenStorageService, AuthService],
  templateUrl: './forgot-password.component.html',
  styleUrl: '../shared/forms-styles.css'
})
export class ForgotPasswordComponent {

  email: any = '';
  responseData: any;

  constructor(
    private _location: Location,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute)
    {
      this.email = '';
    }

  ngOnInit(): void {
    let tempEmail = this.route.snapshot.paramMap.get('email');

    if (tempEmail != null) {
      this.email = tempEmail
    }
  }

  forgotPassword(): void {
    this.authService.ForgotPasswordRequest(this.email).subscribe( response =>{
      this.responseData = response;
      this._location.back();
    });
  }

}
