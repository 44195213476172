import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsManageComponent } from "../events-manage/events-manage.component";
import { MatTableModule } from '@angular/material/table';
import { FormsModule } from '@angular/forms';
import { UserService } from '../shared/user-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-main',
  standalone: true,
  imports: [CommonModule, EventsManageComponent, FormsModule, MatTableModule],
  providers: [UserService],
  templateUrl: './user-main.component.html',
  styleUrl: '../shared/forms-styles.css'
})
export class UserMainComponent {
  @Output() roleSelected = new EventEmitter<{ userId: any, role: string }>();

  search: string = "";

  userList: any = null;
  roles: string[] = ['admin', 'user'];

  constructor(private userService: UserService, private router: Router) {

  }

  ngOnInit(): void{
    this.FetchUsers();
  }

  FetchUsers(): void {
    this.userService.GetUsers().subscribe( response =>{
      this.userList = response.data;
    });
  }

  onSelectRole(userId: any, role: string) {
    const user = this.userList.find((userItem: any) => userItem.id === userId);
    console.log(userId, role);
    if (user) {

      this.userService.SetUserRole(userId, role).subscribe( response =>{

        let data = response as any;

        if(data.success){
          user.role = role;
          this.roleSelected.emit({ userId, role });
        } else {
          user.role = data.user.role;
          this.roleSelected.emit({ userId, role });
        }
      });
    }}

    DisplayUserManageView(userId: number): void {
      this.router.navigate(['/users-manage', userId]);
    }

    DisplayUserParticipantsManageView(userId: number): void {
      this.router.navigate(['/users-participants', userId]);
    }

}
