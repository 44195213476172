
<div>
  <div class="title">{{event?.title}} Participant Manager</div>
  <br>
  <div *ngIf="ShowParticipantsList(); else noParticipants">
    <div class="entry_title">Current Participants</div>

    <mat-table class="table_tiles" [dataSource]="participantsList">
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row?.pivot.id}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="name_event_participants">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row?.first_name}} {{row?.last_name}}</mat-cell>
      </ng-container>

      <!-- Status Definition -->
      <ng-container matColumnDef="view">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button class="icon_button" (click)="DisplayEventParticipantDetails(row.pivot.id)"> <img class="svg" src="assets/svg/eye.svg" alt="view"> </button>
        </mat-cell>
      </ng-container>

      <!-- Status Definition -->
      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button class="icon_button" (click)="EditEventParticipantDetails(row.pivot.id)"> <img class="svg" src="assets/svg/edit.svg" alt="edit"> </button>
        </mat-cell>
      </ng-container>

      <!-- Status Definition -->
      <ng-container matColumnDef="edit_questions">
        <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button class="icon_button" (click)="EditEventParticipantQuestions(row.pivot.id)"> <img class="svg" src="assets/svg/checklist.svg" alt="edit"> </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="paid">
        <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button class="icon_button" (click)="DisplayPaymentManagePage(row.pivot.id, row.user_id)"> <img class="svg" src="assets/svg/paid.svg" alt="payment"> </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="remove">
        <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button class="icon_button" (click)="RemoveParticipantFromEvent(row.pivot.id)"> <img class="svg" src="assets/svg/person_remove.svg" alt="remove"> </button>
        </mat-cell>
      </ng-container>

      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="['id', 'name_event_participants', 'view', 'edit', 'edit_questions', 'paid', 'remove']"></mat-header-row>
      <mat-row *matRowDef="let row; columns: ['id', 'name_event_participants', 'view', 'edit', 'edit_questions', 'paid', 'remove']"></mat-row>
    </mat-table>
        <br>
    <button (click)="CopyEmailList()"> Copy Email List to Clipboard </button>
  </div>
  <ng-template #noParticipants>
    There are currently no participants registered for this event.
  </ng-template>
</div>

<br>
<br>
<br>
<br>
<div *ngIf="userParticipantsList" class="horizontal-container">
  <div class="horizontal-item entry_title" style="width: 50%">
    <div class="entry_title">Users </div>
    <div>
      <mat-form-field>
        <mat-select [(value)]="selectedOption" (selectionChange)="onSelectionChange($event)">
          <mat-option *ngFor="let option of filteredOptions" [value]="option?.id">{{ option?.first_name + " " + option?.last_name  }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="horizontal-item entry_title" style="width: 150%">
    <div>
      <div class="entry_title">User Filter</div>
      <div>
        <mat-form-field>
          <mat-label>First Name</mat-label>
          <input matInput (keyup)="filterOptionsFirstName($event)" placeholder="Search">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Last Name</mat-label>
          <input matInput (keyup)="filterOptionsLastName($event)" placeholder="Search">
        </mat-form-field>
      </div>
    </div>
  </div>
</div>

<br>

<div *ngIf="userParticipantsList; else noUserParticipants">
  <div class="entry_title"> Dependants </div>
  <mat-table class="table_tiles" [dataSource]="userParticipantsList">

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row?.first_name}} {{row?.last_name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="add">
      <mat-header-cell *matHeaderCellDef> Add </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button class="icon_button" (click)="AddParticipant(row.id)"> <img class="svg" src="assets/svg/person_add.svg" alt="add"> </button>
      </mat-cell>
    </ng-container>



    <!-- Header and Row Declarations -->
    <mat-header-row *matHeaderRowDef="['name', 'add']"></mat-header-row>
    <mat-row *matRowDef="let row; columns: ['name', 'add']"></mat-row>
  </mat-table>
</div>
<ng-template #noUserParticipants>
  This User has no Participants.
</ng-template>


