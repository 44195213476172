
<ng-container *ngIf="isLoggedin(); else loginTemplate">
  <app-main-layout>
  </app-main-layout>
</ng-container>

<ng-template #loginTemplate>
  <router-outlet></router-outlet>
</ng-template>




