import { inject } from '@angular/core';
import { CanMatchFn, Router, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, catchError, of } from 'rxjs';

export const authGuardGuard: CanMatchFn = (route, segments) => {

  const router = inject( Router );
  const authService = inject(AuthService);

  return new Observable<boolean | UrlTree>(observer => {

      authService.IsAuthenticated().subscribe(response => {
        let data: any = response;
        console.log("Auth Guard data: " + JSON.stringify(data));

        if (data.user.role != 'admin') {
          router.navigate(['/not-authorized']);
          observer.next(false);
          observer.complete();
          return;
        }

        if (data.isLoggedIn) {
          observer.next(true);
          observer.complete();
          return;
        } else {
          //observer.next(router.createUrlTree(['']));
          //observer.complete();
        }
    });


  });
}










