import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs"
import { tap, delay, catchError, map, first } from "rxjs/operators"
import { TokenStorageService } from "./token-storage.service"
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { environment } from "../../environments/environment"

interface LoginResult {
  success: boolean;
  message?: string;
}

@Injectable({
  providedIn : 'root'
})

export class AuthService {

    redirectUrl: string|null = null;
    isLoggedInFlag: boolean = false;


    constructor(private tokenStorageService: TokenStorageService, private http: HttpClient) {
      this.tokenStorageService = tokenStorageService;
      this.http = http;
    }

    IsAuthenticated() {
      let token =  this.tokenStorageService.getToken();
      let headers_object = new HttpHeaders({
        'Content-Type': 'application/json',
         'Authorization': "Bearer " + token});

        const httpOptions = {
          headers: headers_object
        };

      let request = this.http.get<Request>(environment.url+'/auth/isLoggedIn', httpOptions).pipe(
        catchError((error: any) => {
          console.error("IsAuthenticated failed:", error);
          return of({ isLoggedIn: false });
        })
      );

      return request;
    }

    Login(userName: string, password: string): Observable<LoginResult> {
      return this.LoginAttempt(userName, password).pipe(
        map(response => {
          console.log("Service data: " + JSON.stringify(response));
          let responseData: any;
          responseData = response;
          this.tokenStorageService.setToken(responseData.authorisation.token);
          return { success: true };
        }),
        catchError(error => {
          console.error("Login failed:", error);
          return of({ success: false, message: "Login failed. Please check your credentials." });
        })
      );
    }

    Register(userName: string, password: string, firstname: string, lastname: string): Observable<LoginResult> {
      return this.RegisterAttempt(userName, password, firstname, lastname).pipe(
        map(response => {
          console.log("Service data: " + JSON.stringify(response));
          let responseData: any;
          responseData = response;
          this.tokenStorageService.setToken(responseData.authorisation.token);
          return { success: true };
        }),
        catchError(error => {
          console.error("Login failed:", error);
          return of({ success: false, message: "Login failed. Please check your credentials." });
        })
      );
    }

    ForgotPasswordRequest(email: string){
      return this.http.post<Request>(environment.url+'/auth/forgot-password', { email: email });
    }

    RegisterAttempt(userName: string, password: string, firstname: string, lastname: string) {
      return this.http.post<Request>(environment.url+'/auth/register', { email: userName, password: password, first_name: firstname, last_name: lastname });
    }

    LoginAttempt(userName: string, password: string) {
      return this.http.post<Request>(environment.url+'/auth/login', { email: userName, password: password });
    }

    Logout(): void {
        this.tokenStorageService.removeToken();
    }

    Reverify() {

      let token =  this.tokenStorageService.getToken();

      let data = {}

      let headers_object = new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': "Bearer " + token,
        });
        const httpOptions = {
          headers: headers_object
        };

      return this.http.post<Request>(environment.url+'/auth/email/verify/resend', data , httpOptions);
    }

    isLoggedIn(){
      let token =  this.tokenStorageService.getToken()

      if (token == null){
        return false;
      }

      return !this.isTokenExpired(token);
    }


    isTokenExpired(token: any) {
      if (!token) return true; // Token not provided, consider it expired

      const tokenData = JSON.parse(atob(token.split('.')[1])); // Decode token payload
      const expirationTime = tokenData.exp * 1000; // Convert expiration time to milliseconds

      // Check if the current time is greater than the token's expiration time
      return Date.now() >= expirationTime;
    }


}
