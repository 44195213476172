import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms'
import { TokenStorageService } from '../shared/token-storage.service';
import { LoginResponse } from '../shared/models/login-response.model';
import { AuthService } from '../shared/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule],
  providers: [TokenStorageService, AuthService],
  templateUrl: './login.component.html',
  styleUrl: '../shared/forms-styles.css'
})

export class LoginComponent {

  userName: string;
  password: string;

  testAPIString: string;

  responseData: any;

  constructor(
    private authService: AuthService,
    private router: Router)
    {
      this.userName = '';
      this.password = '';
      this.testAPIString = "Test API";
      this.responseData = new LoginResponse(null, null, null);
    }

  login(): void {
    this.authService.Login(this.userName, this.password).subscribe( response =>{
      this.responseData = response;
      this.router.navigate(['events']);
    });
  }

  logout(): void {
    this.authService.Logout();
  }

  register(): void {
    this.router.navigate(['register']);
  }

  forgotPassword(): void {
    this.router.navigate(['/forgot-password', this.userName]);
  }

  reverify(): void {
    console.log("userName: " + this.userName + " password: " + this.password);
    this.authService.Reverify().subscribe( response =>{
      this.responseData = response;
      console.log("Login Component data: " + JSON.stringify(response));
      this.router.navigate(['main']);
    });

  }
}
