<div *ngIf="LatestEventParticipantDetails">
<div #input_container>
  <div class="title"> Participant Management </div>
  <div class="container">
    <div class="selector-box entry_title">
      First Name <br>
      <input class="select" type="text" disabled placeholder="First Name" [(ngModel)]="LatestEventParticipantDetails.first_name">
    </div>
    <div class="selector-box-right entry_title">
      Last Name <br>
      <input class="select" type="text" disabled placeholder="Last Name" [(ngModel)]="LatestEventParticipantDetails.last_name">
    </div>
  </div>
  <div class="container">
    <div class="selector-box entry_title">
      Date of Birth <br>
      <input class="select" type="date" disabled placeholder="Date of Birth" [(ngModel)]="LatestEventParticipantDetails.birthdate">
    </div>
    <div class="selector-box-right entry_title">
      Preferred Pronouns <br>
      <input class="select" type="text" placeholder="Preferred Pronouns" [(ngModel)]="LatestEventParticipantDetails.preferred_pronouns">
    </div>
  </div>
  <div class="container">
    <div class="selector-box entry_title">
      Phone Number <br>
      <input class="select" type="text" placeholder="Phone Number" [(ngModel)]="LatestEventParticipantDetails.phone">
    </div>
    <div class="selector-box-right entry_title">
      Email Address <br>
      <input class="select" type="text" placeholder="Email Address" [(ngModel)]="LatestEventParticipantDetails.email">
    </div>
  </div>
  <div class="container">
    <div class="selector-box entry_title" style="width: 100%">
      Medical Information <br>
      <div class="horizontal-container" style="width: 65%">
        <div class="horizontal-item entry_subtitle">
            Provide important medical information.
        </div>
        <div class="horizontal-item entry_subtitle">
          <input type="checkbox" [(ngModel)]="LatestEventParticipantDetails.has_medical_info">
        </div>
      </div>
      <textarea [hidden]="DisableMedicalInfo()" class="select" type="text" rows="5" placeholder="Medical Information" style="height: 100px;" [(ngModel)]="LatestEventParticipantDetails.medical_info"></textarea>
    </div>
  </div>
  <div class="container">
    <div class="selector-box entry_title" style="width: 100%;">
      Learner Preferences <br>
      <div class="horizontal-container" style="width: 65%">
        <div class="horizontal-item" >
          <div class="entry_subtitle">Provide important learner preferences.</div>
        </div>
        <div class="horizontal-item">
          <input type="checkbox" [(ngModel)]="LatestEventParticipantDetails.has_learner_preferences">
        </div>
      </div>
      <textarea [hidden]=" DisableLearnerPreferences()"  class="select" type="text" placeholder="Learner Preferences" style="height: 100px;" [(ngModel)]="LatestEventParticipantDetails.learner_preferences"></textarea>
    </div>
  </div>
  <div class="container" style="margin-top:15px">
    <div class="entry_title">
      Emergency Contacts
        <div>
          <div *ngIf="showEmergencyContact[1]">
            <div class="container">
              <div class="selector-box-firstname entry_title" >
                <input class="select" type="text" placeholder="First Name"  #myInput (blur)="OnEmergencyFirstNameInputBlur(myInput.value, 1)" [(ngModel)]="emergencyContactFirstNameTempString[1]">
              </div>
              <div class="selector-box-lastname entry_title"  >
                <input class="select" type="text" placeholder="Last Name"  #myInput2 (blur)="OnEmergencyLastNameInputBlur(myInput2.value, 1)" [(ngModel)]="emergencyContactLastNameTempString[1]">
              </div>
              <div class="selector-box-phone entry_title" >
                <input class="select" type="text" placeholder="Phone" #myInput3 (blur)="OnEmergencyPhoneInputBlur(myInput3.value, 1)" [(ngModel)]="emergencyContactPhoneTempString[1]">
              </div>
              <div class="selector-box-remove entry_title" *ngIf="this.showEmergencyContactRemoveButton(1)">
                <button class="button-delete-mid" (click)="this.removeEmergencyContactLine(1)"> REMOVE </button> <br>
              </div>
            </div>
          </div>
          <div *ngIf="showEmergencyContact[2]">
            <div class="container" >
              <div class="selector-box-firstname entry_title" >
                <input class="select" type="text" placeholder="First Name"  #myInput (blur)="OnEmergencyFirstNameInputBlur(myInput.value, 2)" [(ngModel)]="emergencyContactFirstNameTempString[2]">
              </div>
              <div class="selector-box-lastname s entry_title"  >
                <input class="select" type="text" placeholder="Last Name"  #myInput2 (blur)="OnEmergencyLastNameInputBlur(myInput2.value, 2)" [(ngModel)]="emergencyContactLastNameTempString[2]">
              </div>
              <div class="selector-box-phone entry_title" >
                <input class="select" type="text" placeholder="Phone" #myInput3 (blur)="OnEmergencyPhoneInputBlur(myInput3.value, 2)" [(ngModel)]="emergencyContactPhoneTempString[2]">
              </div>
              <div class="selector-box-remove entry_title" *ngIf="this.showEmergencyContactRemoveButton(2)">
                <button class="button-delete-mid" (click)="this.removeEmergencyContactLine(2)"> REMOVE </button> <br>
              </div>
            </div>
          </div>
          <div *ngIf="showEmergencyContact[3]">
            <div class="container" >
              <div class="selector-box-firstname  entry_title">
                <input class="select" type="text" placeholder="First Name"  #myInput (blur)="OnEmergencyFirstNameInputBlur(myInput.value, 3)" [(ngModel)]="emergencyContactFirstNameTempString[3]">
              </div>
              <div class="selector-box-lastname  entry_title">
                <input class="select" type="text" placeholder="Last Name"  #myInput2 (blur)="OnEmergencyLastNameInputBlur(myInput2.value, 3)" [(ngModel)]="emergencyContactLastNameTempString[3]">
              </div>
              <div class="selector-box-phone entry_title">
                <input class="select" type="text" placeholder="Phone" #myInput3 (blur)="OnEmergencyPhoneInputBlur(myInput3.value, 3)" [(ngModel)]="emergencyContactPhoneTempString[3]">
              </div>
              <div class="selector-box-remove entry_title" *ngIf="this.showEmergencyContactRemoveButton(3)">
                <button class="button-delete-mid" (click)="this.removeEmergencyContactLine(3)"> REMOVE </button> <br>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  <div class="container" style="margin-top:25px">
    <div class="entry_title">
      Authorized Pickups
      <div>
        <div *ngIf="showAuthorizedPickup[1]">
          <div class="container" >
            <div class="selector-box-firstname entry_title" >
              <input class="select" type="text" placeholder="First Name"  #myInput (blur)="OnAuthorizedFirstNameInputBlur(myInput.value, 1)" [(ngModel)]="authorizedPickupFirstNameTempString[1]">
            </div>
            <div class="selector-box-lastname entry_title"  >
              <input class="select" type="text" placeholder="Last Name"  #myInput2 (blur)="OnAuthorizedLastNameInputBlur(myInput2.value, 1)" [(ngModel)]="authorizedPickupLastNameTempString[1]">
            </div>
            <div class="selector-box-phone entry_title" >
              <input class="select" type="text" placeholder="Phone" #myInput3 (blur)="OnAuthorizedPhoneInputBlur(myInput3.value, 1)" [(ngModel)]="authorizedPickupPhoneTempString[1]">
            </div>
            <div class="selector-box-remove entry_title" *ngIf="this.showAuthorizedRemoveButton(1)">
              <button class="button-delete-mid" (click)="this.removeAuthorizedLine(1)"> REMOVE </button> <br>
            </div>
          </div>
        </div>
        <div *ngIf="showAuthorizedPickup[2]">
          <div class="container" >
            <div class="selector-box-firstname entry_title" >
              <input class="select" type="text" placeholder="First Name"  #myInput (blur)="OnAuthorizedFirstNameInputBlur(myInput.value, 2)" [(ngModel)]="authorizedPickupFirstNameTempString[2]">
            </div>
            <div class="selector-box-lastname entry_title"  >
              <input class="select" type="text" placeholder="Last Name"  #myInput2 (blur)="OnAuthorizedLastNameInputBlur(myInput2.value, 2)" [(ngModel)]="authorizedPickupLastNameTempString[2]">
            </div>
            <div class="selector-box-phone entry_title" >
              <input class="select" type="text" placeholder="Phone" #myInput3 (blur)="OnAuthorizedPhoneInputBlur(myInput3.value, 2)" [(ngModel)]="authorizedPickupPhoneTempString[2]">
            </div>
            <div class="selector-box-remove entry_title" *ngIf="this.showAuthorizedRemoveButton(2)">
              <button class="button-delete-mid" (click)="this.removeAuthorizedLine(2)"> REMOVE </button> <br>
            </div>
          </div>
        </div>
        <div *ngIf="showAuthorizedPickup[3]">
          <div class="container" >
            <div class="selector-box-firstname entry_title" >
              <input class="select" type="text" placeholder="First Name"  #myInput (blur)="OnAuthorizedFirstNameInputBlur(myInput.value, 3)" [(ngModel)]="authorizedPickupFirstNameTempString[3]">
            </div>
            <div class="selector-box-lastname entry_title"  >
              <input class="select" type="text" placeholder="Last Name"  #myInput2 (blur)="OnAuthorizedLastNameInputBlur(myInput2.value, 3)" [(ngModel)]="authorizedPickupLastNameTempString[3]">
            </div>
            <div class="selector-box-phone entry_title" >
              <input class="select" type="text" placeholder="Phone" #myInput3 (blur)="OnAuthorizedPhoneInputBlur(myInput3.value, 3)" [(ngModel)]="authorizedPickupPhoneTempString[3]">
            </div>
            <div class="selector-box-remove entry_title" *ngIf="this.showAuthorizedRemoveButton(3)">
              <button class="button-delete-mid" (click)="this.removeAuthorizedLine(3)"> REMOVE </button> <br>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="selector-box entry_title" style="width: 100%;">
      Notes <br>
      <textarea  class="select" type="text" placeholder="Notes" style="height: 100px;" [(ngModel)]="LatestEventParticipantDetails.note"></textarea>
    </div>
  </div>

  <div *ngIf="canEdit" class="entry_title" style="width: 100%; margin-top:25px;">
    <button class="button" (click)="CreateNewUserParticipantDetailsRecord()"> EDIT PARTICIPANT </button> <br>
  </div>
</div>
</div>
