import {Component} from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsManageComponent } from "../events-manage/events-manage.component";
import { EventService } from '../shared/event-service.service'
import { EventParticipantsComponent } from "../event-participants/event-participants.component";
import { MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatSelectModule} from '@angular/material/select';
import { MatFormFieldModule} from '@angular/material/form-field';

@Component({
    selector: 'app-events-main',
    standalone: true,
    templateUrl: './events-main.component.html',
    styleUrl: '../shared/forms-styles.css',
    imports: [CommonModule, EventsManageComponent, EventParticipantsComponent, FormsModule, MatTableModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule],
    providers: [EventService]
})
export class EventsMainComponent {

  search: string = "";
  dataSource: Array<EventForList> = [];
  filteredDataSource : Array<EventForList> = [];
  eventType: string = 'draft';
  eventTypes = new FormControl<string[]>([]);
  eventTypesList: string[] = ['published', 'draft', 'past', 'archived'];

  constructor(private eventService: EventService, private router: Router) {

  }

  ngOnInit(): void{
    this.RefreshList();
    this.eventTypes.setValue(['draft', 'published']);
    this.eventTypes.valueChanges.subscribe((selectedValues: string[] | null) => {
      this.onEventTypesChange(selectedValues);
    });
  }

  RefreshList(): void {
    this.eventService.GetEvents().subscribe( response =>{
      this.dataSource = response;
      this.dataSource.forEach(event => {
        event.end_date = this.getLatestEndDateFromSchedules(event);

        if (event.end_date && event.end_date < new Date() && event.status == 'published') {
          event.status = 'past';
        }

        if (event.is_archived == 1) {
          event.status = 'archived';
        }


      });
      this.FilterDataSource();
    });
  }

  getLatestEndDateFromSchedules(event: EventForList): Date | null {
    const schedules = event.event_schedules;

    // Ensure event_schedules is not empty
    if (Object.keys(schedules).length === 0) {
      return null;
    }

    // Initialize the latest date to the first schedule's end date
    let latestDate: Date | null = null;

    // Iterate through each schedule to find the furthest out end date
    for (const key in schedules) {
      if (schedules.hasOwnProperty(key)) {
        const endDate = new Date(schedules[key].end);

        // Initialize latestDate or update if the current endDate is later
        if (!latestDate || endDate > latestDate) {
          latestDate = endDate;
        }
      }
    }

    // Return the latest date as a string or null if no valid date found
    return latestDate;
  }

  onEventTypesChange(selectedValues: string[] | null) {
    console.log('Selected Event Types:', selectedValues);
    this.FilterDataSource();
  }

 FilterDataSource(): void {
    var selectedStatuses = this.eventTypes.value && this.eventTypes.value.length > 0 ? this.eventTypes.value : [this.eventType];
    this.filteredDataSource = this.dataSource.filter(event => selectedStatuses.includes(event.status));
  }

  NavigateCreateEventsView(): void {
    this.router.navigate(['/events-create', 'create']);
  }

  DisplayEventManageView(eventId: number): void {
    this.router.navigate(['/events-manage', eventId, 'edit' ]);
  }

  DisplayParticipantsView(eventId: number): void {
    this.router.navigate(['/events-participants', eventId]);
  }

  DisplayAttendanceView(eventId: number): void {
    this.router.navigate(['/attendance', eventId]);
  }



}

export interface EventForList {
  id: number;
  end_date: Date | null;
  event_schedules: { [key: string]: { end: string } };
  location: string;
  is_archived: number;
  title: string;
  date: Date;
  status: string;
  current_participants: number;
  max_participants: number;
}






