import { Component, OnInit } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentService } from '../shared/payment-service.service';
import { environment } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-event-participant-payment',
  standalone: true,
  imports: [],
  providers: [PaymentService],
  templateUrl: './event-participant-payment.component.html',
  styleUrl: './event-participant-payment.component.css'
})


export class EventParticipantPaymentComponent {

   stripe_publishable_key: any = null;
   stripePromise: any = null;


  constructor(private paymentService: PaymentService, private route: ActivatedRoute) {

  }

  async ngOnInit() {

    this.stripe_publishable_key = this.route.snapshot.paramMap.get('checkout_secret');

    this.stripePromise = loadStripe(environment.stripe_secret);

    const stripe = await this.stripePromise;

    const fetchClientSecret = async () => {
      //const response = this.paymentService.createCheckoutSession() as any; // Extract the value from the Observable
      return this.stripe_publishable_key;
    };

    if (stripe) { // Add null check
      const checkout = await stripe.initEmbeddedCheckout(
        {
          fetchClientSecret
        }
      );

      checkout.mount('#checkout');
    }
  }
}

