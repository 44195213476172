import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsManageComponent } from "../events-manage/events-manage.component";
import { MatTableModule } from '@angular/material/table';
import { FormsModule } from '@angular/forms';
import { UserService } from '../shared/user-service.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ParticipantService } from '../shared/participant-service.service';
import { NgZone } from '@angular/core';


@Component({
  selector: 'app-user-manage',
  standalone: true,
  imports: [CommonModule, EventsManageComponent, FormsModule, MatTableModule],
  providers: [ParticipantService, UserService],
  templateUrl: './user-manage.component.html',
  styleUrl: './user-manage.component.css'
})

export class UserManageComponent {

  userID: any = null;
  userFirstName: any = null;
  userLastName: any = null;
  userEmail: any = null;
  userPhone: any = null;
  userAddress: any = null;
  userNote: any = null;
  userRole: string = 'user';
  participantsList: any = null;

  constructor(private userService: UserService, private participantService: ParticipantService, private route: ActivatedRoute, private router: Router, private zone: NgZone) {

    this.userID = 1;
    this.userFirstName = "John";
    this.userLastName = "Doe";
    this.userEmail = "";
    this.userPhone = "";
    this.userRole = "user"
    this.userNote = "";
    this.userAddress = "";
  }

  ngOnInit(): void{
    this.userID = this.route.snapshot.paramMap.get('id');

    this.userService.GetUser(this.userID).subscribe((response: any) => {
      let data = response.data;
      this.userID = data.id;
      this.userEmail = data.email;
      if (data.role === "user") {
        this.userRole = "value1";
      } else {
        this.userRole = "value2";
      }

      this.userFirstName = data.first_name;
      this.userPhone = data.phone;
      this.userNote = data.note;
      this.userAddress = data.address;

    });

    this.FetchParticipants();
  }

  FetchParticipants(): void {
    this.participantService.GetUserParticipants(this.userID).subscribe( response =>{
      this.participantsList = response;
    });
  }

  PreventNonNumeric(event: KeyboardEvent): void {
    const key = event.key;

    // Allow navigation keys like backspace, delete, arrows, etc.
    if (key === 'Backspace' || key === 'Delete' || key === 'ArrowLeft' || key === 'ArrowRight') {
      return;
    }

    // Prevent non-numeric input (excluding keys like tab, enter, etc.)
    if (!/^\d$/.test(key)) {
      event.preventDefault(); // Stop the key from being added to the input field
    } else {
      // Ensure the input is a number
      const input = event.target as HTMLInputElement;
      if (input.value.length >= 10) {
        event.preventDefault();
      }
    }
  }

  Save(){
    let role;
    if (this.userRole === "value1") {
       role = "user";
    } else {
       role = "admin";
    }

    // Ensure all of the phone numbers are 10 digits
    const phoneRegex = /^\d{10}$/;
    const validPhone = " A valid phone number is 10 digits, without the country code.";

    if (!phoneRegex.test(this.userPhone)) {
      alert("Please enter a valid participant phone number."  + validPhone);
      return;
    }

    this.userService.UpdateUser(this.userID, this.userFirstName, this.userLastName, role, this.userPhone, this.userNote, this.userAddress).subscribe((response: any) => {
      this.router.navigate(['/users']);
    });
  }

}
