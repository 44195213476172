import { ActivatedRoute, Router} from '@angular/router';
import { Component} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule} from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatDialog} from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { EventService } from '../shared/event-service.service';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ParticipantService } from '../shared/participant-service.service';

@Component({
  selector: 'app-attendance-sheet',
  standalone: true,
  imports: [CommonModule, FormsModule, MatFormFieldModule, MatSelectModule, MatTableModule, MatCheckboxModule],
  templateUrl: './attendance-sheet.component.html',
  styleUrl: '../shared/forms-styles.css'
})

export class AttendanceSheetComponent {

  event_ID: any = null;
  attendance_data: any = null;
  attendance_data2: any = null;
  dateColumns: any = null;
  dateColumnsIds: any = null;
  displayedColumns: any = null;
  event: any = null;

  constructor(private eventService: EventService, private route: ActivatedRoute, private router: Router, public dialog: MatDialog) {

  }

  ngOnInit() {
    this.event_ID = this.route.snapshot.paramMap.get('event_ID');

    this.getAttendanceData();
  }

  getAttendanceData(){
    this.eventService.GetAttendanceSheet(this.event_ID).subscribe((data: any) => {
      this.attendance_data = data.attendance_data;
      this.event = data.event;
      this.generateColumns();
    });
  }

  getAge (dateString: any) {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() <= birthDate.getDate())) {
      age--;
    }
    return age;
  }

  generateColumns() {
    const sampleAttendance = this.attendance_data.attendance[0];
    if (sampleAttendance) {
        const dateKeys = Object.keys(sampleAttendance.dates);
        this.dateColumns = dateKeys.map(key => ({
            id: key,
            date: sampleAttendance.dates[key].date
        })) as Array<{ id: string; date: string }>;
        this.displayedColumns = ['participant', 'age', ...this.dateColumns.map((col: { key: string; date: string }) => col.date)];
    }
  }

  toggleAttendance(attendance_sheet_id: any) {
    this.attendance_data.attendance.forEach((attendance: any) => {
       Object.keys(attendance.dates).forEach((date: any) => {
          if (attendance.dates[date].id === attendance_sheet_id) {
              if (attendance.dates[date].attended == 'true' || attendance.dates[date].attended == true) {
                attendance.dates[date].attended = false;
              } else {
                attendance.dates[date].attended = true;
              }
          }
        });
    });
  }

  SendAttendanceData(){
    this.eventService.UpdateAttendanceSheet(this.event_ID, this.attendance_data).subscribe((data: any) => {
      this.router.navigate(['/events']);
  });

  }
}
