<div class="center_container">

  <div class="title"> Forgot Password</div>

  <div class="centered_div entry_title">
    Email <br>
    <input style="width: 300px" class="select" type="text" placeholder="Username" [(ngModel)]="email">
  <br>
  <div class="centered_diventry_title">
    <button class="button" (click)="forgotPassword()">Reset Password</button>
  </div>
  <br>
<!--
<div class="selector-box entry_title">
  <div>
      <button class="button" (click)="reverify()">Verify</button>
    </div>
  </div>
!-->
</div>
