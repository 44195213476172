<div class="title">users</div>

<div>
  <mat-table class="table_tiles" [dataSource]="userList">
    <!-- Event Name Definition -->
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.id}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
      <mat-cell  *matCellDef="let row"> {{row.email}}</mat-cell>
    </ng-container>

    <!-- Email Definition -->
    <ng-container matColumnDef="role">
      <mat-header-cell *matHeaderCellDef> Role </mat-header-cell>
      <mat-cell  *matCellDef="let row"> {{row.role}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef> Edit </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button class="icon_button" (click)="DisplayUserManageView(row.id)"> <img class="svg" src="assets/svg/edit.svg" alt="manage"> </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="participants">
      <mat-header-cell *matHeaderCellDef> Participants </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button class="icon_button" (click)="DisplayUserParticipantsManageView(row.id)"> <img class="svg" src="assets/svg/people.svg" alt="manage"> </button>
      </mat-cell>
    </ng-container>

    <!-- Header and Row Declarations -->
    <mat-header-row *matHeaderRowDef="['id', 'email', 'role', 'edit', 'participants']"></mat-header-row>
    <mat-row *matRowDef="let row; columns: ['id', 'email', 'role', 'edit','participants']"></mat-row>
  </mat-table>
</div>
