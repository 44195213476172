import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Request } from './models/request.model';
import { TokenStorageService } from '../shared/token-storage.service';
import { environment } from '../../environments/environment';

  @Injectable({
    providedIn: 'root'
  })

  export class UserService {

   data: any;
   public newText: string;

   constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
      this.newText = "Nothing Selected";
   }

  GetUsers(){
    let t =  this.tokenStorageService.getToken();

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+t});

      const httpOptions = {
        headers: headers_object
      };
    return this.http.get<Request>(environment.url+'/admin/users', httpOptions).pipe()
  }


  GetUsersWithPersonalInformation(){
    let t =  this.tokenStorageService.getToken();

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+t});

      const httpOptions = {
        headers: headers_object
      };
    return this.http.get<Request>(environment.url+'/admin/users/get_users', httpOptions).pipe()
  }

  GetUser(id : number){
    let t =  this.tokenStorageService.getToken();

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+t});
      const httpOptions = {
        headers: headers_object
      };

      const queryParams = new HttpParams().set('id', id);
    return this.http.get<Request>(environment.url+'/admin/users/get_user', {params: queryParams, ...httpOptions}).pipe()
  }


  UpdateUser(userId: number, first_name: string, last_name: string, role: string, phone: string, address: string, note: string)
  {
    let t =  this.tokenStorageService.getToken();

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+t});

      let data = {
        id: userId,
        first_name: first_name,
        last_name: last_name,
        role: role,
        phone: phone,
        address: address,
        note: note
      }

      const httpOptions = {
        headers: headers_object
      };
    return this.http.post<Request>(environment.url+'/admin/users/update', data, httpOptions).pipe()
  }

  SetUserRole(userId: number, role: string){
    let t =  this.tokenStorageService.getToken();

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+t});

      let data = {
        id: userId,
        role: role
      }

      const httpOptions = {
        headers: headers_object
      };
    return this.http.post<Request>(environment.url+'/admin/users/set_role', data, httpOptions).pipe()
  }
 }


