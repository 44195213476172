import { ActivatedRoute, Router} from '@angular/router';
import { Component} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule} from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { EventService } from '../shared/event-service.service'
import { MatDialog} from '@angular/material/dialog';
import { DialogChoiceComponent } from '../dialog-choice/dialog-choice.component';
import { ChangeDetectorRef } from '@angular/core';
import { MatTableModule } from '@angular/material/table';

@Component({
  selector: 'app-event-participant-payment-information',
  standalone: true,
  imports: [CommonModule, FormsModule, MatFormFieldModule, MatTableModule],
  templateUrl: './event-participant-payment-information.component.html',
  styleUrl: '../shared/forms-styles.css'
})
export class EventParticipantPaymentInformationComponent {

  hasPaymentReferences: boolean = false;
  eventId: any;
  eventParticipantId: any;
  paymentInformationData: any;
  constructor(private eventService: EventService, private route: ActivatedRoute, private router: Router,  public dialog: MatDialog, private cdr: ChangeDetectorRef) {

  }

  ngOnInit(): void{
    this.eventId = this.route.snapshot.paramMap.get('event_ID');
    this.eventParticipantId = this.route.snapshot.paramMap.get('event_participant_ID');

    this.eventService.GetEventParticipantPaymentInformation(this.eventId, this.eventParticipantId).subscribe((data: any) => {
      this.paymentInformationData = data;
    });


  }
}


