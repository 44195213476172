<div>
  <div class="title">{{event?.title}} Attendance</div>
    <div *ngIf="this.attendance_data">
    <mat-table class="table_tiles" [dataSource]="this.attendance_data.attendance | keyvalue">
      <!-- Participant Name Definition -->
      <ng-container matColumnDef="participant">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.value.participant.first_name}} {{row.value.participant.last_name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="age">
        <mat-header-cell *matHeaderCellDef> Age </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{this.getAge(row.value.participant.birthdate)}} </mat-cell>
      </ng-container>

      <!-- Dynamic Date Columns Definition -->
      <ng-container *ngFor="let dateColumn of dateColumns" [matColumnDef]="dateColumn.date">
        <mat-header-cell *matHeaderCellDef> {{ dateColumn.date }} </mat-header-cell>
        <mat-cell *matCellDef="let row"> <mat-checkbox [checked]="row.value.dates[dateColumn.id].attended" (change)="this.toggleAttendance(row.value.dates[dateColumn.id].id)"> </mat-checkbox></mat-cell>
    </ng-container>

      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <button class="button" style="width: 200px;" (click)="this.SendAttendanceData()"> Submit Changes </button>
  </div>
</div>
