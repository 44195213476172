import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsManageComponent } from "../events-manage/events-manage.component";
import { MatTableModule } from '@angular/material/table';
import { FormsModule } from '@angular/forms';
import { ParticipantService } from '../shared/participant-service.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-participants',
  standalone: true,
  imports: [CommonModule, EventsManageComponent, FormsModule, MatTableModule],
  providers: [ParticipantService],
  templateUrl: './user-participants.component.html',
  styleUrl: '../shared/forms-styles.css'
})

export class UserParticipantsComponent {
  userID: any = null;
  participantsList: any = null;

  constructor (private participantService: ParticipantService, private route: ActivatedRoute, private router: Router) {

  }

  ngOnInit(): void{
    this.userID = this.route.snapshot.paramMap.get('id');
    this.FetchParticipants();
  }

  FetchParticipants(): void {
    this.participantService.GetUserParticipants(this.userID).subscribe( response =>{
      this.participantsList = response;
    });
  }

  AddParticipant(): void{
    this.router.navigate(['/users-participant-create', this.userID]);
  }

  ViewParticipant(id: number): void{
    this.router.navigate(['/users-participant-view', this.userID, id, false]);
  }

  EditParticipant(id: number): void{
    this.router.navigate(['/users-participant-edit', this.userID, id, true]);
  }

}
