export class LoginResponse {
    public status;
    public user;
    public authorisation;

    constructor(data: any, user: any, authorisation: any) {
      this.status = data;
      this.user = user;
      this.authorisation = authorisation;
    }
}
