<div class="title"> Participant Management </div>

      <div class="container">
        <div class="selector-box entry_title">
          First Name <br>
          <input class="select" type="text" placeholder="First Name" [(ngModel)]="UserParticipantData.first_name">
        </div>
        <div class="selector-box-right entry_title">
          Last Name <br>
          <input class="select" type="text" placeholder="Last Name" [(ngModel)]="UserParticipantData.last_name">
        </div>
      </div>
      <div class="container">
        <div class="selector-box entry_title">
          Date of Birth <br>
          <input class="select" type="date" placeholder="Date of Birth" [(ngModel)]="UserParticipantData.birthdate" (blur)="ValidateBirthdate()">
        </div>
        <div class="selector-box-right entry_title">
          Preferred Pronouns <br>
          <input class="select" type="text" placeholder="Preferred Pronouns" [(ngModel)]="UserParticipantData.preferred_pronouns">
        </div>
      </div>
      <div class="container">
        <div class="selector-box entry_title">
          Phone Number <br>
          <input class="select" type="text" placeholder="Phone Number" (keydown)="PreventNonNumeric($event)" [(ngModel)]="UserParticipantData.phone">
        </div>
        <div class="selector-box-right entry_title">
          Email Address <br>
          <input class="select" type="email" placeholder="Email Address" [(ngModel)]="UserParticipantData.email">
        </div>
      </div>
      <div class="container">
        <div class="selector-box entry_title" style="width: 100%">
          Medical Information <br>
          <div class="horizontal-container" style="width: 65%">
            <div class="horizontal-item entry_subtitle">
                Provide important medical information.
            </div>
            <div class="horizontal-item entry_subtitle">
              <input type="checkbox" [(ngModel)]="UserParticipantData.has_medical_info">
            </div>
          </div>
          <textarea [hidden]="DisableMedicalInfo()" class="select" type="text" rows="5" placeholder="Medical Information" style="height: 100px;" [(ngModel)]="UserParticipantData.medical_info"></textarea>
        </div>
      </div>
      <div class="container">
        <div class="selector-box entry_title" style="width: 100%;">
          Learner Preferences <br>
          <div class="horizontal-container" style="width: 65%">
            <div class="horizontal-item" >
              <div class="entry_subtitle">Provide important learner preferences.</div>
            </div>
            <div class="horizontal-item">
              <input type="checkbox" [(ngModel)]="UserParticipantData.has_learner_preferences">
            </div>
          </div>
          <textarea [hidden]=" DisableLearnerPreferences()"  class="select" type="text" placeholder="Learner Preferences" style="height: 100px;" [(ngModel)]="UserParticipantData.learner_preferences"></textarea>
        </div>
      </div>

      <div class="container" style="margin-top:15px">
        <div class="entry_title"> Emergency Contacts </div>
              <div *ngIf="showEmergencyContact[1]">
                <div class="container" >
                  <div class="selector-box-firstname entry_title" >
                    <input class="select" type="text" placeholder="First Name"  #myInput (blur)="OnEmergencyFirstNameInputBlur(myInput.value, 1)" [(ngModel)]="emergencyContactFirstNameTempString[1]">
                  </div>
                  <div class="selector-box-lastname entry_title"  >
                    <input class="select" type="text" placeholder="Last Name"  #myInput2 (blur)="OnEmergencyLastNameInputBlur(myInput2.value, 1)" [(ngModel)]="emergencyContactLastNameTempString[1]">
                  </div>
                  <div class="selector-box-phone entry_title" >
                    <input class="select" type="text" placeholder="Phone" #myInput3  (keydown)="PreventNonNumeric($event)"  (blur)="OnEmergencyPhoneInputBlur(myInput3.value, 1)" [(ngModel)]="emergencyContactPhoneTempString[1]">
                  </div>
                </div>
              </div>
              <div *ngIf="showEmergencyContact[2]">
                <div class="container" >
                  <div class="selector-box-firstname entry_title" >
                    <input class="select" type="text" placeholder="First Name"  #myInput (blur)="OnEmergencyFirstNameInputBlur(myInput.value, 2)" [(ngModel)]="emergencyContactFirstNameTempString[2]">
                  </div>
                  <div class="selector-box-lastname s entry_title"  >
                    <input class="select" type="text" placeholder="Last Name"  #myInput2 (blur)="OnEmergencyLastNameInputBlur(myInput2.value, 2)" [(ngModel)]="emergencyContactLastNameTempString[2]">
                  </div>
                  <div class="selector-box-phone entry_title" >
                    <input class="select" type="text" placeholder="Phone" #myInput3 (keydown)="PreventNonNumeric($event)"  (blur)="OnEmergencyPhoneInputBlur(myInput3.value, 2)" [(ngModel)]="emergencyContactPhoneTempString[2]">
                  </div>
                </div>
              </div>
              <div *ngIf="showEmergencyContact[3]">
                <div class="container" >
                  <div class="selector-box-firstname  entry_title">
                    <input class="select" type="text" placeholder="First Name"  #myInput (blur)="OnEmergencyFirstNameInputBlur(myInput.value, 3)" [(ngModel)]="emergencyContactFirstNameTempString[3]">
                  </div>
                  <div class="selector-box-lastname  entry_title">
                    <input class="select" type="text" placeholder="Last Name"  #myInput2 (blur)="OnEmergencyLastNameInputBlur(myInput2.value, 3)" [(ngModel)]="emergencyContactLastNameTempString[3]">
                  </div>
                  <div class="selector-box-phone entry_title">
                    <input class="select" type="text" placeholder="Phone" #myInput3 (keydown)="PreventNonNumeric($event)"  (blur)="OnEmergencyPhoneInputBlur(myInput3.value, 3)" [(ngModel)]="emergencyContactPhoneTempString[3]">
                  </div>
                </div>
              </div>
            </div>
      <div class="container" style="margin-top:25px">
        <div class="entry_title">Authorized Pickups</div>
            <div *ngIf="showAuthorizedPickup[1]">
              <div class="container" >
                <div class="selector-box-firstname entry_title" >
                  <input class="select" type="text" placeholder="First Name"  #myInput (blur)="OnAuthorizedFirstNameInputBlur(myInput.value, 1)" [(ngModel)]="authorizedPickupFirstNameTempString[1]">
                </div>
                <div class="selector-box-lastname entry_title"  >
                  <input class="select" type="text" placeholder="Last Name"  #myInput2 (blur)="OnAuthorizedLastNameInputBlur(myInput2.value, 1)" [(ngModel)]="authorizedPickupLastNameTempString[1]">
                </div>
                <div class="selector-box-phone entry_title" >
                  <input class="select" type="text" placeholder="Phone" #myInput3 (keydown)="PreventNonNumeric($event)"  (blur)="OnAuthorizedPhoneInputBlur(myInput3.value, 1)" [(ngModel)]="authorizedPickupPhoneTempString[1]">
                </div>
              </div>
            </div>
            <div *ngIf="showAuthorizedPickup[2]">
              <div class="container" >
                <div class="selector-box-firstname entry_title" >
                  <input class="select" type="text" placeholder="First Name"  #myInput (blur)="OnAuthorizedFirstNameInputBlur(myInput.value, 2)" [(ngModel)]="authorizedPickupFirstNameTempString[2]">
                </div>
                <div class="selector-box-lastname entry_title"  >
                  <input class="select" type="text" placeholder="Last Name"  #myInput2 (blur)="OnAuthorizedLastNameInputBlur(myInput2.value, 2)" [(ngModel)]="authorizedPickupLastNameTempString[2]">
                </div>
                <div class="selector-box-phone entry_title" >
                  <input class="select" type="text" placeholder="Phone" #myInput3  (keydown)="PreventNonNumeric($event)" (blur)="OnAuthorizedPhoneInputBlur(myInput3.value, 2)" [(ngModel)]="authorizedPickupPhoneTempString[2]">
                </div>
              </div>
            </div>
            <div *ngIf="showAuthorizedPickup[3]">
              <div class="container" >
                <div class="selector-box-firstname entry_title" >
                  <input class="select" type="text" placeholder="First Name"  #myInput (blur)="OnAuthorizedFirstNameInputBlur(myInput.value, 3)" [(ngModel)]="authorizedPickupFirstNameTempString[3]">
                </div>
                <div class="selector-box-lastname entry_title"  >
                  <input class="select" type="text" placeholder="Last Name"  #myInput2 (blur)="OnAuthorizedLastNameInputBlur(myInput2.value, 3)" [(ngModel)]="authorizedPickupLastNameTempString[3]">
                </div>
                <div class="selector-box-phone entry_title" >
                  <input class="select" type="text" placeholder="Phone" #myInput3 (keydown)="PreventNonNumeric($event)" (blur)="OnAuthorizedPhoneInputBlur(myInput3.value, 3)" [(ngModel)]="authorizedPickupPhoneTempString[3]">
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="selector-box entry_title" style="width: 100%;">
              Notes <br>
              <textarea  class="select" type="text" placeholder="Notes" style="height: 100px;" [(ngModel)]="UserParticipantData.note"></textarea>
            </div>
          </div>

      <div class="entry_title" style="width: 100%; margin-top:25px;">
        <button class="button" (click)="SaveParticipant()"> Save PARTICIPANT </button> <br>
      </div>

