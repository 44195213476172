import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsManageComponent } from "../events-manage/events-manage.component";
import { MatTableModule } from '@angular/material/table';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDialog} from '@angular/material/dialog';
import { DialogChoiceComponent } from './dialog-choice.component';
import { PopupModalComponent } from '../shared/popup-modal/popup-modal.component';
import { UserService } from '../shared/user-service.service';
import { EventService } from '../shared/event-service.service';

@Component({
  selector: 'app-event-participants',
  standalone: true,
  imports: [CommonModule, EventsManageComponent, FormsModule, MatTableModule, MatInputModule, MatSelectModule],
  providers: [EventService, UserService],
  templateUrl: './event-participants.component.html',
  styleUrl: '../shared/forms-styles.css'
})
export class EventParticipantsComponent {

  view: string = 'view';
  edit: string = 'edit';

  userList: any = null;
  eventId : any;
  event: any = null;
  userID: any = null;
  participantFirstName: any = null;
  participantLastName: any = null;
  participantAge: any = null;
  participantsList: any = null;
  userParticipantsList: any = null;

  options: any[];
  filteredOptions: any[];
  selectedOption: any = '';

  firstNameSearchTerm: any = "";
  lastNameSearchTerm: any = "";

  constructor(private userService: UserService, private eventService: EventService, private route: ActivatedRoute, private router: Router, public dialog: MatDialog) {
    this.options = this.userList;
    this.filteredOptions = this.userList;
  }

    ngOnInit() {
      this.eventId = this.route.snapshot.paramMap.get('id');
      this.FetchUsers();
      this.FetchParticipants();
      this.options = this.userList;
    }

    onSelectionChange(event: any) {
      // Access the selected item
      let user = this.userList?.find((user: any) => user.id === this.selectedOption);
      if (user != null && this.filteredOptions != null && this.filteredOptions.length > 0) {
        this.userParticipantsList = this.userList?.find((user: any) => user.id === this.selectedOption).participants;
      } else {
        this.userParticipantsList = null;
      }
    }

    filterOptionsFirstName(event: any) {
      const value = (event.target as HTMLInputElement).value;
      this.firstNameSearchTerm = value;
      this.filteredOptions = this.options.filter(option => option?.first_name?.toLowerCase().includes(value.toLowerCase()));
      this.filteredOptions = this.filteredOptions.filter(option => option?.last_name?.toLowerCase().includes(this.lastNameSearchTerm?.toLowerCase()));
      if(this.filteredOptions != null && this.filteredOptions.length > 0){
        this.selectedOption = this.filteredOptions[0].id;
      }
      this.onSelectionChange(event);
    }

    filterOptionsLastName(event: any) {
      const value = (event.target as HTMLInputElement).value;
      this.lastNameSearchTerm = value;
      this.filteredOptions = this.options.filter(option => option?.last_name?.toLowerCase().includes(value.toLowerCase()));
      this.filteredOptions = this.filteredOptions.filter(option => option?.first_name?.toLowerCase().includes(this.firstNameSearchTerm?.toLowerCase()));
      if(this.filteredOptions != null && this.filteredOptions.length > 0){
        this.selectedOption = this.filteredOptions[0].id;
      }
      this.onSelectionChange(event);
    }
    // Fetch Users

    FetchUsers() {
      this.userService.GetUsersWithPersonalInformation().subscribe(data => {
        this.userList = data.data;
        this.options = this.userList;
        this.filteredOptions = this.userList

        if (this.filteredOptions != null && this.filteredOptions.length > 0) {
          this.selectedOption = this.filteredOptions[0].id;
          this.onSelectionChange(null);
        }

      });
    }

    FetchParticipants() {
      this.eventService.GetEventParticipants(this.eventId).subscribe(data => {
        let response = data as any;
        this.participantsList = response.participants;
        this.event = response.event;
      });
    }

    AddParticipant(id: number) {

      this.eventService.CreateEventParticipant(this.eventId, id).subscribe(data => {
        let result = data as any;

        if (!result.success){
        const dialogRef = this.dialog.open(PopupModalComponent,  {
          restoreFocus: false,
          width: '350px',
          data: {
            message: result.error,
            title: "Oops!",
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result === true) {
            // Delete logic here
            this.eventService.RemoveEventParticipant(id).subscribe(data => {
              this.participantsList = data;
            });
          }
        });
        }

      this.FetchParticipants();
      });
    }

    DisplayEventParticipantDetails(id: number){
      console.log("EVENT PARTICIPANT ID: " + id);
      this.router.navigate(['/events-participant-manage', this.view, id, this.eventId]);
    }

    EditEventParticipantDetails(id: number){
      this.router.navigate(['/events-participant-manage', this.edit, id, this.eventId]);
    }

    EditEventParticipantQuestions(id: number){
      this.router.navigate(['/events-participant-questions', this.edit, id, this.eventId]);
    }

    DisplayPaymentManagePage(id: number, user_id: number){
      this.router.navigate(['/events-participant-payment-manage', id, this.eventId, user_id]);
    }

    // DisplayPaymentManagePage(id: number){
    //   this.router.navigate(['/events-participant-payment-manage', id, this.eventId]);
    // }

    RemoveParticipantFromEvent(id: number) {

      let participant = this.participantsList.find((participant: any) => participant.pivot.id === id);

      const dialogRef = this.dialog.open(DialogChoiceComponent,  {
        restoreFocus: false,
        width: '350px',
        data: {
          question: "Are you sure you want to Remove the participant from this event?",
          title: "Remove Participant?",
          participant: participant?.first_name + " " + participant?.last_name,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          // Delete logic here
          this.eventService.RemoveEventParticipant(id).subscribe(data => {
            this.participantsList = data;
          });
        }
      });
    }

    ShowParticipantsList() {
      let showList = false;

      if (this.participantsList != null && this.participantsList.length > 0) {
        showList = true;
      }
      return showList;
    }

    CopyEmailList () {
      // Create a comma separated list of emails from the participantsList as participant and participant.user.email
      let emailList: string[] = [];
      let emailListString = "";
      this.participantsList.forEach((participant: any) => {

        // if last entry, don't add comma
        let endSymbol = ">, ";
        if (this.participantsList.indexOf(participant) === this.participantsList.length - 1) {
          endSymbol = ">";
        }

        let email = participant.email;
        if (email == null || email == "") {
          email = participant.user.email;
        }
        emailListString += "\"" + participant.first_name + " " + participant.last_name + "\" <" + email + endSymbol;
      });


      navigator.clipboard.writeText(emailListString).then(() => {
        console.log('Text copied to clipboard');
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });

    }
}
