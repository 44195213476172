import { ActivatedRoute, Router} from '@angular/router';
import { Component} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule} from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatDialog} from '@angular/material/dialog';
import { Question } from '../events-manage/events-manage.component';
import { MatSelectModule } from '@angular/material/select';
import { EventService } from '../shared/event-service.service';

@Component({
  selector: 'app-event-participant-questions',
  standalone: true,
  imports: [CommonModule, FormsModule, MatFormFieldModule, MatSelectModule],
  providers: [EventService],
  templateUrl: './event-participant-questions.component.html',
  styleUrl: '../shared/forms-styles.css'
})

export class EventParticipantQuestionsComponent {

  event_participant_ID: any = null;
  event_ID: any = null;
  questions: Question[] = [];
  answers: Answer[] = [];
  answerOptions: any = {'null': 'not selected',
                        1: 'yes',
                        0: 'no'};

  project_permission: boolean = false;
  photo_permission: boolean = false;

  constructor(private eventService: EventService, private route: ActivatedRoute, private router: Router, public dialog: MatDialog) {

  }

    ngOnInit() {
      this.event_participant_ID = this.route.snapshot.paramMap.get('event_participant_ID');
      this.event_ID = this.route.snapshot.paramMap.get('event_ID');
      this.FetchQuestions();
    }

    FetchQuestions(){
      this.eventService.GetEventParticipantQuestions(this.event_ID, this.event_participant_ID).subscribe((data: any) => {
        this.questions = data.questions;
        this.answers = data.answers;
        this.project_permission = data.event_participant.project_permission;
        this.photo_permission = data.event_participant.photo_permission;
        for(let i = 0; i < this.questions.length; i++) {
          if (this.questions[i].answer == null) {
            this.answers[i] = new Answer(undefined, this.questions[i].id, ''); // Assuming AnsweR is the class name for your answer object
          } else {
            this.answers[i] = new Answer(undefined, this.questions[i].id, this.questions[i].answer);
          }
        }
      });
    }

    onSelectionChange(question_id: number, answer: string) {

    }

    SubmitAnswers() {
      this.eventService.SubmitEventParticipantAnswers(this.event_ID, this.event_participant_ID, this.answers, this.project_permission, this.photo_permission).subscribe((data: any) => {
        this.router.navigate(['/events-participants', this.event_ID]);
      });
    }

}

export class Answer {
  constructor(public id: number | undefined, public event_question_id: number | undefined,  public answer: string) {}
}
