import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet, Router } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AuthService } from '../shared/auth.service';
import { HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [CommonModule, RouterOutlet, MatSidenavModule, MatButtonToggleModule, HttpClientModule],
  providers: [AuthService],
  templateUrl: './app-main-layout.component.html',
  styleUrl: './app-main-layout.component.css'
})


export class AppMainLayoutComponent {

  constructor(private authService: AuthService, private router: Router) {
    //  this.router.navigate(['/events']);
  }

  onDashboardClick() {

  }

  onEventsClick() {
    this.router.navigate(['/events']);
  }

  onUsersClick() {
    this.router.navigate(['/users']);
  }

  onLogoutClick() {
    this.authService.Logout();
    this.router.navigate(['/login']);
  }

}
