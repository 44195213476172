import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AppMainLayoutComponent } from './app-main-layout/app-main-layout.component';
import { LoginComponent } from './login/login.component';
import { AuthService } from './shared/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, MatSidenavModule, MatButtonToggleModule, HttpClientModule, AppMainLayoutComponent, LoginComponent],
  providers: [AuthService],
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {
  newText: string = "Nothing Selected";
  title = 'maker-space-app-frontend';
  selectedColor: string = "black";
  unselectedColor: string = "white";
  data: any;

  constructor(private authService: AuthService, private route: Router) {

    if (!this.authService.isLoggedIn()) {
      this.route.navigate(['login']);
    }
  }

  onDashboardClick() {
    this.newText = "Dashboard";
  }

  onEventsClick() {
    this.newText = "Events";
  }

  onUsersClick() {
    this.newText = "Users";
  }

  onNotificationsClick() {
    this.newText = "Notifications1";
  }

  getSidenavContent() {
    let show = false;
    if (this.newText == "Events") {
      show = true;
    }
    return show;
  }

  isLoggedin() {
    return this.authService.isLoggedIn();
  }

}

