<div class="title"> Participant Payment Management </div>

<div *ngIf="this.paymentData">
  <div *ngIf="this.paymentData.eventParticipant.status != 'paid'">
    <div class="container">
      <div class="horizontal-container">
        <div class="horizontal-item" style="width:60%">
          <div class="horizontal-container">
            <div class="horizontal-item entry_title" style="width: 100%">
                Participant Name: {{this.paymentData.eventParticipant.participant.first_name}} {{this.paymentData.eventParticipant.participant.last_name}}
            </div>
          </div>
          <div class="horizontal-container">
            <div class="horizontal-item entry_title" style="width: 100%">
                Event Name: {{this.paymentData.event.title}}
            </div>
          </div>
          <div class="horizontal-container">
            <div class="horizontal-item entry_title" style="width: 100%">
                Event Price: ${{this.paymentData.event.event_price}}
            </div>
          </div>
          <div class="horizontal-container">
            <div class="horizontal-item entry_title" style="width: 100%">
                Scholarships Available: {{this.paymentData.event.max_scholarships - this.paymentData.event.used_scholarships}}
            </div>
          </div>
            <div class="horizontal-item" style="width: 100%">
                Scholarship Request
            </div>
            <select [(ngModel)]="scholarshipRequest" class="select">
              <option value="no_scholarship"> No Scholarship </option>
              <option value="50pct_scholarship"> 50% Scholarship</option>
              <option value="100pct_scholarship"> 100% Scholarship</option>
            </select>
        </div>

        <button class="button" style="width: 200px;" (click)="this.DisplayPaymentPage()"> Intitiate Payment </button>
      </div>
  </div>


  <div *ngIf="this.paymentData != null">

  </div>
</div>





