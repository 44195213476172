<div class="center_container">

    <div class="title"> Makerspace Login </div>

    <div class="centered_div entry_title">
      Email <br>
      <input style="width: 300px" class="select" type="text" placeholder="Username" [(ngModel)]="userName">
      Password <br>
      <input style="width: 300px"class="select" type="password" placeholder="Password" [(ngModel)]="password">
    </div>
    <br>
    <div class="centered_diventry_title">
      <button class="button login-btn" (click)="login()">Login</button>
      <!-- <button class="button" (click)="register()">Register</button>
      <br>
      <button class="button" (click)="forgotPassword()">Forgot Password</button> -->
    </div>
    <br>
  <!--
  <div class="selector-box entry_title">
    <div>
        <button class="button" (click)="reverify()">Verify</button>
      </div>
    </div>
  !-->
</div>
