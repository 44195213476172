import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsManageComponent } from "../events-manage/events-manage.component";
import { MatTableModule } from '@angular/material/table';
import { FormsModule } from '@angular/forms';
import { PaymentService } from '../shared/payment-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-event-participant-payment-manage',
  standalone: true,
  imports: [CommonModule, EventsManageComponent, FormsModule, MatTableModule, MatInputModule, MatSelectModule],
  providers: [PaymentService],
  templateUrl: './event-participant-payment-manage.component.html',
  styleUrl: '../shared/forms-styles.css'
})
export class EventParticipantPaymentManageComponent {

  userList: any = null;
  eventId : any;
  event: any = null;
  userID: any = null;
  participantFirstName: any = null;
  participantLastName: any = null;
  participantAge: any = null;
  participantsList: any = null;
  userParticipantsList: any = null;
  eventParticipantId: any = null;

  options: any[];
  filteredOptions: any[];
  selectedOption: any = '';

  firstNameSearchTerm: any = "";
  lastNameSearchTerm: any = "";

  paymentData: any = null;
  scholarshipRequest: any = null;

  constructor(private paymentService: PaymentService, private route: ActivatedRoute, private router: Router, public dialog: MatDialog) {
    this.options = this.userList;
    this.filteredOptions = this.userList;
  }

  async ngOnInit() {
    this.userID = this.route.snapshot.paramMap.get('user_ID');
    this.eventId = this.route.snapshot.paramMap.get('event_ID');
    this.eventParticipantId = this.route.snapshot.paramMap.get('event_participant_ID');
    this.scholarshipRequest = "no_scholarship";

    this.paymentService.GetEventParticipantPaymentInformation(this.eventId, this.eventParticipantId).subscribe((data: any) => {
      this.paymentData = data;
    });

  }

  DisplayPaymentPage(){
    if (this.scholarshipRequest == '100pct_scholarship'){
      this.paymentService.RequestStripePaymentView(this.eventId, this.eventParticipantId, this.userID, this.scholarshipRequest).subscribe((data: any) => {
        this.router.navigate(['/events-participants', this.eventId]);
      });
    } else {
      this.paymentService.RequestStripePaymentView(this.eventId, this.eventParticipantId, this.userID, this.scholarshipRequest).subscribe((data: any) => {
        this.router.navigate(['/events-participant-payment', this.eventParticipantId, this.eventId, data.clientSecret]);
      });

    }
  }
}
