
<div class="center_container">

  <div class="title"> Makerspace Admin Register - DEVELOPMENT MODE </div>

<div>

  <div class="centered_div entry_title">
    First Name <br>
    <input type="text" class="select" placeholder="First Name" [(ngModel)]="firstname" (input)="this.ValidateFirstNameInput($event)">
    Last Name <br>
    <input type="text" class="select" placeholder="Last Name" [(ngModel)]="lastname" (input)="this.ValidateLastNameInput($event)">
    Email <br>
    <input type="email" class="select" placeholder="Email" [(ngModel)]="email">
    Password <br>
    <div class="horizontal-container">
      <div class="horizontal-item entry_title" style="width: 75%">
        <input #passwordInput type="password" class="select" placeholder="Password" [(ngModel)]="password">
      </div>
      <div class="horizontal-item entry_title" style="width: 25%">
        <button *ngIf="this.showPassword == true" class="icon_button" style="margin-top:9px" (click)="this.TogglePasswordVisibility(false)"> <img class="svg" src="assets/svg/visibility_lock.svg" alt="view"> </button>
        <button *ngIf="this.showPassword == false" class="icon_button" style="margin-top:9px" (click)="this.TogglePasswordVisibility(true)"> <img class="svg" src="assets/svg/visibility.svg" alt="view"> </button>
      </div>
    </div>

    <div class="horizontal-container">
      <div class="horizontal-item entry_title" style="width: 75%">
        <input #passwordInputRepeat type="password" class="select" placeholder="Repeat Password" [(ngModel)]="passwordRepeat">
      </div>
      <div class="horizontal-item entry_title" style="width: 25%">
        <button *ngIf="this.showPasswordRepeat == true" class="icon_button" style="margin-top:9px" (click)="this.TogglePasswordVisibilityRepeat(false)"> <img class="svg" src="assets/svg/visibility_lock.svg" alt="view"> </button>
        <button *ngIf="this.showPasswordRepeat == false" class="icon_button" style="margin-top:9px" (click)="this.TogglePasswordVisibilityRepeat(true)"> <img class="svg" src="assets/svg/visibility.svg" alt="view"> </button>
      </div>
    </div>

  </div>
  <br>
  <div class="centered_diventry_title">
    <button class="button" (click)="register()">Register</button>
  </div>
</div>

</div>


