import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Request } from './models/request.model';
import { TokenStorageService } from '../shared/token-storage.service';
import { environment } from '../../environments/environment';
import { map } from 'rxjs';

  @Injectable({
    providedIn: 'root'
  })

  export class ParticipantService {

   data: any;
   public newText: string;

   constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
      this.newText = "Nothing Selected";
   }

  GetUserParticipants(userID: number) {

    let t =  this.tokenStorageService.getToken();

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+t});

      const httpOptions = {
        headers: headers_object
      };

      const queryParams = new HttpParams().set('userID', userID);

    return this.http.get<Request>(environment.url+'/admin/users/participants/list', {params: queryParams, ...httpOptions}).pipe()
  }

  GetUserParticipant(participantID: number) {
    let t =  this.tokenStorageService.getToken();

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+t});

      const httpOptions = {
        headers: headers_object
      };

      const queryParams = new HttpParams().set('participantID', participantID);

    return this.http.get<Request>(environment.url+'/admin/users/participants/get_participant', {params: queryParams, ...httpOptions}).pipe(
      map(response => {

       let handledResponse: any = response;
        // Merge participant_details onto the response object
        return {
          ...handledResponse,
          ...handledResponse.participant_details[0]
        };
      })
    );
  }

  CreateUserParticipant(UserParticipantData: any) {

    let t =  this.tokenStorageService.getToken();

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+t});

      const httpOptions = {
        headers: headers_object
      };

    return this.http.post<Request>(environment.url+'/admin/users/participants/create', UserParticipantData, httpOptions).pipe()
  }

  EditUserParticipant(UserParticipantData: any) {

    let t =  this.tokenStorageService.getToken();

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+t});

      const httpOptions = {
        headers: headers_object
      };

    return this.http.post<Request>(environment.url+'/admin/users/participants/edit', UserParticipantData, httpOptions).pipe()
  }

 }


