import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms'
import { AuthService } from '../shared/auth.service';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [FormsModule, CommonModule],
  providers: [AuthService],
  templateUrl: './register.component.html',
  styleUrl: '../shared/forms-styles.css'
})

export class RegisterComponent {
  @ViewChild('passwordInput') passwordInput!: ElementRef;
  @ViewChild('passwordInputRepeat') passwordInputRepeat!: ElementRef;

  firstname: string;
  lastname: string;
  email: string;
  password: string;
  passwordRepeat: string;
  showPassword: boolean = false;
  showPasswordRepeat: boolean = false;
  constructor(
    private authService: AuthService,
    private router: Router)
    {
      this.email = '';
      this.firstname = '';
      this.lastname = '';
      this.password = '';
      this.passwordRepeat = '';
    }



  ValidateFirstNameInput(event: Event): void {
      const input = event.target as HTMLInputElement;
      input.value = input.value.replace(/[^a-zA-Z\s]/g, '');
      this.firstname = input.value; // Update the ngModel binding
  }

  ValidateLastNameInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^a-zA-Z\s]/g, '');
    this.lastname = input.value; // Update the ngModel binding
}

  register(): void {

    // Check if email is valid
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(this.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    if (this.email.length < 1) {
      alert("Please enter your email address.");
      return;
    }

    // Check if last name is valid

    if (this.lastname.length < 1) {
      alert("Please enter your last name.");
      return;
    }

    if (this.password !== this.passwordRepeat) {
      alert("Passwords do not match");
      return;
    }

    if (this.password.length < 8) {
      alert("Password must be more than 8 characters");
      return;
    }

    this.authService.Register(this.email, this.password, this.firstname, this.lastname).subscribe( response =>{
      console.log("Login Component data: " + JSON.stringify(response));
    });
  }

  TogglePasswordVisibility(set: boolean): void {
    const passwordField = this.passwordInput.nativeElement;
    if (passwordField) {
      if (set) {
        passwordField.setAttribute('type', 'text');
      } else {
        passwordField.setAttribute('type', 'password');
      }
      this.showPassword = set;
    }
  }

  TogglePasswordVisibilityRepeat(set: boolean): void {
    const passwordField = this.passwordInputRepeat.nativeElement;
    if (passwordField) {
      if (set) {
        passwordField.setAttribute('type', 'text');
      } else {
        passwordField.setAttribute('type', 'password');
      }
      this.showPasswordRepeat = set;
    }
  }
}
