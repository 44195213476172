<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav class="sidenav" mode="side" opened>

    <div class="sidenav-logotitle">
      <img class="sidenav-logo" src="\assets\images\Logo_PinnguaqIconWebColourDark.png" alt="Logo">
      <div class="sidenav-title">MAKERSPACE <br> ADMIN PANEL</div>
    </div>


  <mat-button-toggle-group name="nav-items" vertical="true" class="sidenav-navigation">
    <mat-button-toggle class="sidenav-navigation-item" value="Nav2" (click)="onEventsClick()">
      EVENTS
    </mat-button-toggle>

    <mat-button-toggle class="sidenav-navigation-item" value="Nav3" (click)="onUsersClick()">
      USERS
    </mat-button-toggle>

    <mat-button-toggle class="sidenav-navigation-item" value="Nav3" (click)="onLogoutClick()">
      [ log out ]
    </mat-button-toggle>
  </mat-button-toggle-group>


  </mat-sidenav>

  <div class="sidenav-content">
    <div class="ro">
      <router-outlet></router-outlet>
    </div>
  </div>

</mat-sidenav-container>
