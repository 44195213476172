import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { ExtraOptions, provideRouter, withRouterConfig } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient } from '@angular/common/http';
import { routes } from './app.routes';
import { AuthService } from './shared/auth.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

const routerOptions: ExtraOptions = {
  useHash: true,
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    provideAnimations(),
    provideHttpClient(),
    importProvidersFrom(MatDialogModule, MatSnackBarModule),
    AuthService
  ]
};
