import { ActivatedRoute, Router} from '@angular/router';
import { Component, ElementRef, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule} from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { EventService } from '../shared/event-service.service'
import { MatDialog} from '@angular/material/dialog';
import { DialogChoiceComponent } from '../dialog-choice/dialog-choice.component';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-events-manage',
  standalone: true,
  imports: [CommonModule, FormsModule, MatFormFieldModule],
  providers: [EventService],
  templateUrl: './events-manage.component.html',
  styleUrl: '../shared/forms-styles.css'
})

export class EventsManageComponent {

  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('minAgeInput') minAgeInput!: ElementRef;
  @ViewChild('maxAgeInput') maxAgeInput!: ElementRef;

  eventId: any;
  state: any;
  event: any = {};
  eventName: string = '';
  facilitator: string = '';
  description: string = '';
  date: Date = new Date();
  status: string = '';
  fileName = '';
  file: File | null = null;;
  url: any;
  current_url: any;
  imagePresent: boolean = false;
  isPaid: boolean = false;
  eventPrice: number = 0;
  maxParticipants: number = 0;
  currentParticipants: any = 0;
  maxScholarships: number = 0;
  address: string = '';
  scholarshipsAwarded: any = 0;
  imageExists: boolean = false;
  regions: any = [];
  region: number = 1;

  locations: any = null;
  location: any | null = null;

  schedules : DateItem[] = [];
  questions : Question[] = [];

  allAges: boolean = false;

  minAge: number = 0;
  maxAge: number = 0;

  virtualEvent: boolean = false;
  virtualEventOptional: boolean = false;

  currentDateTime: string = '';

  show_master_buttons: boolean = false;
  isSubmitted: boolean = false;

  answerTypes = [
    { value: 'true_false', label: 'True / False' },
    { value: 'short_answer', label: 'Short Answer' }
  ];

  constructor(private eventService: EventService, private route: ActivatedRoute, private router: Router,  public dialog: MatDialog, private cdr: ChangeDetectorRef) {
    this.currentDateTime = this.getCurrentDateTime();
  }

  ngOnInit(): void{
    this.currentDateTime = this.getCurrentDateTime();
    this.eventId = this.route.snapshot.paramMap.get('id');
    this.state = this.route.snapshot.paramMap.get('state');

    if (this.state === 'edit') {
      this.FetchEvent();
    } else {
      this.FetchLocations();
      this.minAge = 0;
      this.maxAge = 0;
      this.maxParticipants = 0;
      this.maxScholarships = 0;
      if (this.minAge != null && this.maxAge != null){
        if (this.minAge == 0 && this.maxAge == 0){
          this.allAges = true;
        }
      }
      if (this.minAge == null || this.maxAge == null){
        this.allAges = true;
      }
      this.schedules.push(new DateItem(undefined, undefined, undefined));
    }
  }

  getCurrentDateTime(): string {
    const now = new Date();
    return now.toISOString().slice(0,16); // Format to 'YYYY-MM-DDTHH:mm'
  }

  FetchLocations(): void {
    this.eventService.GetLocations().subscribe( response =>{
      this.show_master_buttons = true;
      this.regions = response;
      if (this.location != null){
        for (let region of this.regions){
          for(let location of region.locations){
              if (location.id == this.location){
                this.region = location.region_id;
              }
          }
        }
      } else {
        // set region to region that is "Ontario"
        for (let region of this.regions){
          if (region.region_name == "Ontario"){
            this.region = region.id;
            break;
          } else {
            this.region = 0;
          }
        }
        this.regions.map((region: any) => {
          if (region.id == this.region){
            this.locations = region.locations;
            this.location = region.locations[0].id;
          }
        });
      }
      this.OnRegionSelect();

    });
  }

  FetchEvent(): void {
    this.eventService.GetEvent(this.eventId).subscribe( response =>{
      this.event = response;
      this.show_master_buttons = true;
      this.eventName = this.event.title;
      this.facilitator = this.event.facilitator;
      this.description = this.event.description;
      this.date = this.event.date;
      this.status = this.event.status;
      this.url = this.event.image_url;
      this.address = this.event.address;
      this.current_url = this.url;
      if (this.event?.event_price > 0){
        this.isPaid = true;
      }

      this.eventPrice = this.event.event_price;
      this.maxParticipants = this.event.max_participants;
      this.maxScholarships = this.event.max_scholarships;
      this.currentParticipants = this.event.participantCount;
      this.scholarshipsAwarded = this.event.scholarshipsAwarded;

      if (this.status == 'published'){
        this.state = 'published';
      }

      if (this.current_url != null && this.current_url != "") {
        this.imagePresent = true;
      }

      if (this.event.event_schedules.length != null && this.event.event_schedules.length > 0){
        this.schedules = this.event.event_schedules;
      }

      if (this.event.event_questions.length != null && this.event.event_questions.length > 0){
        this.questions = this.event.event_questions;
      }

      this.schedules.push(new DateItem(undefined, undefined, undefined));

      this.reorderSchedules();

      this.regions = this.event.regions;
      this.location = this.event.location_id;

      if (this.location == null){
        for (let region of this.regions){
          if (region.region_name == "Ontario"){
            this.region = region.id;

          } else {
            this.region = 0;
          }
        }
        this.regions.map((region: any) => {
          if (region.id == this.region){
            this.locations = region.locations;
            this.location = region.locations[0].id;
          }
        });
      } else {
        // select region basedo n location id
        for (let region of this.regions){
          for(let location of region.locations){
              if (location.id == this.location){
                this.region = location.region_id;
              }
          }
      }}

      if (this.event.min_age != null && this.event.min_age >= 0){
        this.minAge = this.event.min_age;
      } else {
        this.minAge = 0;
      }

      if (this.event.max_age != null && this.event.max_age >= 0){
        this.maxAge = this.event.max_age;
      } else {
        this.maxAge = 0;
      }

      if (this.event.max_age != null && this.event.min_age != null){
        if (this.event.max_age == 0 && this.event.min_age == 0){
          this.allAges = true;
        }
      }
      if (this.event.max_age == null || this.event.min_age == null){
        this.allAges = true;
      }


      this.allAges = this.event.is_all_ages;
      this.virtualEvent = this.event.is_virtual;
      this.virtualEventOptional = this.event.is_optional_virtual;
      this.OnRegionSelect();
    });
  }

  EditPublishedEvent(): void {
    this.openEditPublishedEventDialog("This event is already published, are you sure you want to edit the event?", "Confirm Edit Published Event");
  }

  SaveEdit(withNavigateAway: boolean = true): void {

    if (this.minAge != null && this.maxAge != null){
      if (this.minAge == 0 && this.maxAge == 0){
        this.allAges = true;
      }
    }
    if (this.minAge== null || this.maxAge == null){
      this.allAges = true;
    }

    this.eventService.EditEvent(this.eventId,
                                this.eventName,
                                this.date,
                                this.status,
                                this.facilitator,
                                this.description,
                                this.fileName,
                                this.schedules,
                                this.questions,
                                this.eventPrice,
                                this.maxParticipants,
                                this.maxScholarships,
                                this.location,
                                this.address,
                                this.minAge,
                                this.maxAge,
                                this.allAges,
                                this.virtualEvent,
                                this.virtualEventOptional
                              ).subscribe(response =>{

      if (this.file != null) {
        this.UploadFile(this.file);
      }

      if (withNavigateAway){
        this.router.navigate(['/events']);
      }

    });
  }

  OnRegionSelect(): void {
      this.regions.map((region: any) => {
        if (region.id == this.region){
          this.locations = region.locations;
          if (this.location == null){
            this.location = region.locations[0].id;
          }
          // if current location isn't found in region
          let found = false;
          for (let location of region.locations){
            if (location.id == this.location){
              found = true;
            }
          }
          if (!found){
            this.location = region.locations[0].id;
          }
        }
      });
  }


  onFileSelected(event: any): void {
    const file:File = event.target.files[0];
    if (file) {
        this.file = file;
        this.fileName = file.name;
        const formData = new FormData();
        formData.append("file", file);

        const img = new Image();
        let reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]); // read file as data url

        reader.onload = (event) => { // called once readAsDataURL is completed
          this.current_url = event.target!.result;

          img.src = event.target!.result as string;
          img.onload = () => {
            if (img.width != 1024 || img.height != 512) {
              alert("Image dimensions must be 1024px by 512px");
              this.file = null;
              this.fileName = "";
              this.current_url = "";
              this.imagePresent = false;
            }
            else {
              if (this.current_url) {
                this.imagePresent = true;
              }
            }
          }
        }
    }
  }

  DeleteImage(): void {
    this.openDeleteImageDialog("Are you sure you want to delete this image?", "Delete image?");
  }

  ShowScholarshipsAwarded(): boolean {
    let show = false;
    if (this.state === 'edit') {
      show = true;
    }
    return show
  }

  UploadFile(file: File): void {
    // Upload logic here
    this.eventService.UploadThumbnail(this.eventId, file).subscribe(response =>{
      console.log(response);
    });
  }

  CreateEvent(): void {
    this.isSubmitted = true; 

    if (!this.eventName || this.maxParticipants < 1) {
        return; 
    }

    this.eventService.CreateEvent(this.eventName, this.date, "draft", this.facilitator, this.description, this.location, this.address, this.minAge, this.maxAge, this.allAges, this.fileName, this.schedules, this.questions, this.eventPrice, this.maxParticipants, this.maxScholarships,this.virtualEvent).subscribe(response =>{
      let eventCreated: any = response;
      this.eventId = eventCreated.id
      if (this.file != null) {
        this.UploadFile(this.file);
      }

      this.router.navigate(['/events']);
    });
  }

  PromptPublishEvent(): void {
    this.openPublishEventDialog("Are you sure you want to publish this event? Published events cannot be unpublished.", "Publish event?");
  }

  PublishEvent(): void {
    var navigateAwayAfterSafe = false;
    this.SaveEdit(navigateAwayAfterSafe);

    this.eventService.PublishEvent(this.eventId).subscribe({
        next: (response) => {
          // This block handles a successful response
          let data: any = response;
          console.log("Response: ", data);
          alert(data.message || "Event published successfully!");
          this.router.navigate(['/events']);
        },
        error: (err) => {
          // This block handles errors (e.g., 400 Bad Request)
          let data: any = err;
          console.log(" test " + data);
          alert(data.error.error || "An error occurred while publishing the event. You must fill out all fields before publishing.");
        }
      });
  }

  DeleteEvent(): void {
    this.openDeleteEventDialog("Are you sure you want to delete this event?", "Delete event?");
  }



  OnStartScheduleBlur(item: DateItem, num: number){
    this.OnScheduleBlur(item, num);

  }


  OnEndScheduleBlur(item: DateItem, num: number){
    this.OnScheduleBlur(item, num);

  }

  OnScheduleBlur(item: DateItem, num: number): void {

    let startSet = false;
    let endSet  = false;

    if (item.start != null && new Date(item.start).getTime() != null &&  !isNaN(new Date(item.start).getTime())){
      startSet = true;
    }

    if (item.end != null && new Date(item.end).getTime() != null && !isNaN(new Date(item.end).getTime())){
      endSet = true
    }

    if(startSet && endSet){
      if (num == this.schedules.length - 1){
        this.schedules.push(new DateItem(undefined, undefined, undefined));
      }

      this.reorderSchedules();
    }

    if(!startSet && !endSet){
      if (!item.start && !item.end){
        if (num + 1 != this.schedules.length){
          this.rebuildDateArray(num);
        }
      }
    }
  }

  reorderSchedules(): void {
    // reorder schedules in by startdate ascending
    this.schedules.sort((a, b) => {
      if (a.start != null && b.start != null){
        if (a.start < b.start){
          return -1;
        }
        if (a.start > b.start){
          return 1;
        }
      }
      return 0;
    });
  }

  OnQuestionBlur(item: Question, num: number): void {

    this.cleanQuestions();
  }

  canAddNewQuestion(): boolean {
    let emptyQuestions: number = 0;
    let index = 0;

    if ( this.questions.length == 0){
      return true;
    }

    for (let question of this.questions) {
      if (!question.question || !question.answer_type) {
        emptyQuestions++;
      }
    }
    if (emptyQuestions > 0 ){
      return false;
    }
    return true;
  }

  cleanQuestions(): void {
    let emptyQuestions: number[] = [];
    let index = 0;
    for (let question of this.questions) {
      if (!question.question || !question.answer_type) {
        emptyQuestions.push(index);
      }
      index++;
    }

    if (emptyQuestions.length > 1){
      this.rebuildQuestionArray(emptyQuestions);
    }
  }

  blurAddress(): void {

  }

  areThereQuestions(): boolean {
    return this.questions.length > 0;
  }

  addQuestion(): void {
    this.questions.push(new Question(undefined, '', '',''));
  }

  openEditPublishedEventDialog(question: string, title: string): void {
    const dialogRef = this.dialog.open(DialogChoiceComponent,  {
      restoreFocus: false,
      width: '350px',
      data: { question: question, title: title}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // Delete logic here
        this.PublishEvent();
      }
    });
  }

  removeQuestion(questionIndex: number): void {
    this.openRemoveQuestionDialog(questionIndex, "Are you sure you want to remove this question? Removing this question will also erase any responses by users to this question if this event has been published.", "Remove Question?");
  }

  removeDate(num: number): void {
    this.rebuildDateArray(num);
  }

  displayRemoveButton(num: number): boolean {
    if (num == this.schedules.length - 1){
      return false;
    }

    if (this.schedules.length <= 1){
      return false;
    }
    return true;
  }

  rebuildDateArray(num: number){
    let newDates: DateItem[] = [];
    for (let i = 0; i < this.schedules.length; i++){
      if (i != num){
        newDates.push(this.schedules[i]);
      }
    }
    this.schedules = newDates;
  }

  rebuildQuestionArray(numsToWithhold: number[]){
    let questions: Question[] = [];
    for (let i = 0; i < this.questions.length; i++){
      if (!numsToWithhold.includes(i)){
        questions.push(this.questions[i]);
      }
    }
    this.questions = questions;
    this.questions.push(new Question(undefined, '', '', ''));
  }

  openPublishEventDialog(question: string, title: string): void {
    const dialogRef = this.dialog.open(DialogChoiceComponent,  {
      restoreFocus: false,
      width: '350px',
      data: { question: question, title: title}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // Publish logic here
        this.PublishEvent();
      }
    });

  }
  openDeleteEventDialog(question: string, title: string): void {
    const dialogRef = this.dialog.open(DialogChoiceComponent,  {
      restoreFocus: false,
      width: '350px',
      data: { question: question, title: title}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // Delete logic here
        this.eventService.ArchiveEvent(this.eventId).subscribe( response =>{
          this.router.navigate(['/events']);
        }
        );
      }
    });
  }

  openDeleteImageDialog(question: string, title: string): void {
    const dialogRef = this.dialog.open(DialogChoiceComponent,  {
      restoreFocus: false,
      width: '350px',
      data: { question: question, title: title}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        if (this.state === 'edit'){
          // Delete logic here
          this.eventService.DeleteImage(this.eventId).subscribe( response =>{
            this.deleteImage();
          });
        }
        else {
          this.deleteImage();
        }
      }
    });
  }

  deleteImage() : void {
    const fileInput = this.fileInput.nativeElement as HTMLInputElement;
    fileInput.value = "";
    fileInput.files = null;

    if (this.file != null){
      this.file = null;
    }

    if (this.fileName != null && this.fileName != ""){
      this.fileName = "";
    }

    if (this.current_url != null && this.current_url != ""){
      this.current_url = "";
    }
    this.imagePresent = false;
    alert("Image deleted successfully");
  }

  openRemoveQuestionDialog(questionIndex: number, question: string, title: string): void {
    const dialogRef = this.dialog.open(DialogChoiceComponent,  {
      restoreFocus: false,
      width: '350px',
      data: { question: question , title: title}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // Delete logic here
        this.questions.splice(questionIndex, 1);
      }
    });
  }


  blurMinAge(): void {
    if (this.minAge < 0 ){
      this.minAge = 0;
    }

    if (this.minAge > this.maxAge){
      this.minAge = this.maxAge;
    }
  }

  blurMaxAge(): void {
    if (this.maxAge < 0 ){
      this.maxAge = 0;
    }

    if (this.minAge > this.maxAge){
      this.minAge = this.maxAge;
    }
  }

  blurEventPrice(): void {
    if (this.eventPrice < 0 ){
      this.eventPrice = 0;
    }

    if (this.eventPrice > 1500 ){
      this.eventPrice = 1500;
    }
  }

  blurEventParticipants(): void {
    if (this.maxParticipants < 0 ){
      this.maxParticipants = 0;
    }
  }
  blurEventScholarships(): void {
    if (this.maxScholarships < 0 ){
      this.maxScholarships = 0;
    }
  }
  isMaxParticipantsInvalid(): boolean {
    return this.maxParticipants < 1 && this.isSubmitted;
}

}


export class DateItem {
  constructor(public id: number | undefined,  public start: Date | undefined, public end: Date | undefined) {}
}


export class Question {
  constructor(public id: number | undefined, public question: string, public answer_type: string, public answer: string) {}
}





