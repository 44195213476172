<div *ngIf="questions">
  <div class="title"> Event Questions </div>



  <div class="horizontal-container">
    <div class="horizontal-item">
      <div class="entry_subtitle" placeholder="Question"> Photo Permission? </div>
    </div>
    <div class="horizontal-item">
      <input type="checkbox" [(ngModel)]="photo_permission"/>
    </div>
  </div>

  <div class="horizontal-container">
    <div class="horizontal-item">
      <div class="entry_subtitle" placeholder="Question"> Project Permission? </div>
    </div>
    <div class="horizontal-item">
      <input type="checkbox" [(ngModel)]="project_permission"/>
    </div>
  </div>


  <br>
  <br>


  <div *ngFor="let item of questions; index as i" style="width:100%">
      <div class="horizontal-item" style="width:55%">
        <div class="entry_subtitle" placeholder="Question">{{item.question}}</div>
      </div>

      <br>
      <div class="txt">
        <div></div>
        <div *ngIf="item.answer_type =='short_answer'">
          <div *ngIf="answers[i]">
            <textarea class="select" rows="3" placeholder="Answer" [(ngModel)]="answers[i].answer" >{{answers[i].answer}}</textarea>
          </div>
          <div *ngIf="!answers[i]">
            <textarea class="select" rows="3" placeholder="Answer" [(ngModel)]="answers[i].answer" ></textarea>
          </div>

        </div>
        <div *ngIf="item.answer_type =='true_false'">
          <div *ngIf="answers[i]">
            <mat-select class="select" [(value)]="answers[i].answer" (selectionChange)="onSelectionChange(i, answers[i].answer)" style="width:30%">
              <mat-option value="">No choice selected</mat-option>
              <mat-option value="true">Yes</mat-option>
              <mat-option value="false">No</mat-option>
            </mat-select>
          </div>
        </div>
        </div>
    <br>
    <br>
  </div>

  <button class="button" style="width:180px" (click)="SubmitAnswers()"> Submit Answers </button>
</div>
