<div class="title"> Participants </div>

<div>


  <div class="entry_title" style="width: 30%; align-self: self-end;">
    <button class="button" (click)="AddParticipant()"> ADD Participant </button> <br>
  </div>

  <mat-table class="table_tiles" [dataSource]="participantsList">

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.first_name}} {{row.last_name}}</mat-cell>
    </ng-container>

    <!-- Date Definition -->
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef> Birthdate </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.birthdate}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="view">
      <mat-header-cell *matHeaderCellDef> View </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button class="icon_button" (click)="ViewParticipant(row.id)"> <img class="svg" src="assets/svg/eye.svg" alt="view"> </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef> Edit </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button class="icon_button" (click)="EditParticipant(row.id)"> <img class="svg" src="assets/svg/edit.svg" alt="edit"> </button>
      </mat-cell>
    </ng-container>

    <!-- Header and Row Declarations -->
    <mat-header-row *matHeaderRowDef="['name', 'date', 'view', 'edit']"></mat-header-row>
    <mat-row *matRowDef="let row; columns: ['name', 'date', 'view', 'edit']"></mat-row>
  </mat-table>

</div>
